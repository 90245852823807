/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  getStoredLanguage,
  getStoredAccessToken,
  getStoredUserLanguage,
  setStoreUserLanguage,
  setStoreLanguage,
} from 'utils/localStorage';
import i18n, { changeLanguage } from 'i18next';
import cn from './locales/zh-cn.json';
import en from './locales/en-us.json';

const resources = {
  'zh-CN': {
    translation: cn,
  },
  'en-US': {
    translation: en,
  },
};
let vivalink_language = getStoredLanguage() ?? 'en-US';
const tokent = getStoredAccessToken();
if (tokent != null && tokent !== '') {
  vivalink_language = getStoredUserLanguage() ?? 'en-US';
}
i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: vivalink_language,
    // detection: {
    //   caches: ['localStorage'],
    // },
  });

export function changeUserLanguageTmp(language) {
  changeLanguage(language ?? 'en-US');
  setStoreUserLanguage(language ?? 'en-US');
}

export function changeLanguageTmp() {
  const language_ = getStoredLanguage() ?? 'en-US';
  changeLanguage(language_);
  setStoreLanguage(language_);
}

export default i18n;
