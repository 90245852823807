import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const isBlantString = (value: string): boolean => {
  if (value == null || value === undefined || value === '') {
    return true;
  }
  return false;
};

export const isNotEmptyObject = (obj: any): boolean => {
  if (typeof obj === 'object') {
    if (Object.keys(obj).length > 0) {
      return true;
    }
  }
  return false;
};

export const timeZone = (): string => {
  return dayjs.tz.guess();
};

export interface TextSizeVo {
  width: number;
  height: number;
}

export function textSize(
  fontSize: string,
  fontFamily: string,
  text: string,
): TextSizeVo {
  const span = document.createElement('span');
  const result: TextSizeVo = {
    width: 0,
    height: 0,
  };
  result.width = span.offsetWidth;
  result.height = span.offsetHeight;
  span.style.visibility = 'hidden';
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;
  span.style.display = 'inline-block';
  document.body.appendChild(span);
  if (typeof span.textContent !== 'undefined') {
    span.textContent = text;
  } else {
    span.innerText = text;
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width;
  result.height =
    parseFloat(window.getComputedStyle(span).height) - result.height;
  span.remove();
  return result;
}
