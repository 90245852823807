// eslint-disable-next-line no-use-before-define
import React, { ReactElement, ReactNode } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { t } from 'i18next';

export interface RHFControllerRender {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onBlur?: () => void;
}

export const checkBoxRender = (
  children?: ReactElement | ReactNode,
  disabled?: boolean,
) => ({ onChange, value }: RHFControllerRender): ReactElement => {
  function handleChange(e: CheckboxChangeEvent): void {
    if (disabled) {
      return;
    }
    if (onChange) {
      onChange(e.target.checked);
    }
  }

  return (
    <Checkbox onChange={handleChange} checked={!!value} disabled={disabled}>
      {children}
    </Checkbox>
  );
};

export const passwordIconRender = (visible: boolean): ReactElement => (
  <Tooltip
    placement="bottom"
    title={visible ? t('hidePassword') : t('showPassword')}
  >
    {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
  </Tooltip>
);
