import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import type { RangePickerProps } from 'antd/es/date-picker';

dayjs.extend(utc);
dayjs.extend(timezone);

export function dateShort(date: Date | string | Dayjs): string | null {
  return date ? dayjs(date).utc().format('MM-DD-YYYY').toString() : null;
}
export function dateTime(
  date: Date | string | Dayjs,
  withMs?: boolean,
): string | null {
  // return date
  //   ? dayjs(date)
  //       .utc()
  //       .format(withMs ? 'hh:mm:ss SSS a' : 'hh:mm:ss a')
  //       .toString()
  //   : null;
  if (date) {
    const utcStr = dayjs(date).utc().format();
    return utcStr ? dayjs(utcStr).format('hh:mm:ss a') : null;
  }
  return '-';
}

export function dateFull(date: Date | string | Dayjs): string | null {
  if (date) {
    const utcStr = dayjs(date).utc().format();
    return utcStr ? dayjs(utcStr).format('YYYY-MM-DD HH:mm:ss') : null;
  }
  return '-';
}

export function ageFull(dateOfBirth: string | Dayjs): number | null {
  return dateOfBirth ? dayjs().diff(dayjs(dateOfBirth), 'year') : null;
}

export const disableDate: RangePickerProps['disabledDate'] = (selected) => {
  return selected && selected >= dayjs().endOf('day');
};

export function dayUTC(timestamp: any): number {
  const localTime = dayjs(timestamp).unix();
  const localTimezoneMinutes = 0 - new Date().getTimezoneOffset();
  return (localTime - localTimezoneMinutes * 60) * 1000;
}

export function UTCLocalTime(timestamp: Date | string | Dayjs): string | null {
  const utcStr = dayjs(timestamp).utc().format();
  const localTime = dayjs(utcStr).format('hh:mm:ss a');
  return localTime;
}

/**
 * UTC Convert TimeZoneTime
 * @param timeZoneOffset_ TimeZoneTime
 * @param time utc Time
 * @returns dayjs.Dayjs
 */
export function handleUTCConvertTimeZoneTime(
  timeZoneOffset_: number,
  time_?: string | Date | number,
): dayjs.Dayjs {
  return dayjs(
    dayjs
      .utc(time_)
      .add(timeZoneOffset_, 'second')
      .format('YYYY-MM-DD HH:mm:ss.SSS'),
  );
}

/**
 * TimeZoneTime To UTC
 * @param timeZoneOffset_ TimeZoneTime
 * @param time_ TimeZoneTime time
 * @returns
 */
export function handleTimeZoneTimeToUTC(
  timeZoneOffset_: number,
  time_?: string | Date | number,
): string {
  return dayjs(time_)
    .subtract(timeZoneOffset_ ?? 0, 'second')
    .format('YYYY-MM-DD HH:mm:ss.SSS');
}

/**
 * UTC时间转换为指定偏移量的本地时间
 * @param targetTimezone 目标时区名称 'America/New_York'
 * @param time_ UTC-0时间字符串 '2023-01-01 00:00:00'
 * @returns
 */
export function handleUTCConvertTimeZoneTimeNew(
  targetTimezone: string,
  time_: string,
): dayjs.Dayjs {
  const utcTime = dayjs.utc(time_); // 这是UTC-0时间
  const localTime = utcTime.tz(targetTimezone); // 将UTC-0时间转换为特定时区的本地时间
  return localTime;
}

/**
 * 指定偏移量的本地时间转换为UTC时间
 * @param targetTimezone 目标时区名称 'America/New_York'
 * @param time_ UTC-0时间字符串 '2023-01-01 00:00:00'
 * @returns
 */
export function handleTimeZoneTimeConvertUTCNew(
  targetTimezone: string,
  time_: string,
): dayjs.Dayjs {
  const localTime = dayjs.tz(time_, targetTimezone); // 使用时区名称
  const utcTime = localTime.utc(); // 将指定时区的时间转换为UTC时间
  return utcTime;
}

/**
 * 指定偏移量的本地时间天结束转换为UTC时间
 * @param targetTimezone 目标时区名称 'America/New_York'
 * @param time_ UTC-0时间字符串 '2023-01-01 00:00:00'
 * @returns
 */
export function handleTimeZoneTimeEndOfDayConvertUTCNew(
  targetTimezone: string,
  time_: string,
): dayjs.Dayjs {
  const localTime = dayjs.tz(time_, targetTimezone).endOf('day'); // 使用时区名称
  const utcTime = localTime.utc(); // 将指定时区的时间转换为UTC时间
  return utcTime;
}

export function roundUpToHour(timeString: string): string {
  // 提取年月日时分秒部分
  const [datePart, timePart] = timeString.split(' ');

  // 提取小时和分钟部分
  const [hour, minute] = timePart.split(':').map(Number);
  // 向上取整到最接近的小时
  let roundedHour = Math.ceil(hour);
  if (minute > 0) {
    roundedHour += 1;
  }

  // 构建向上取整的时间字符串
  const roundedTime = `${datePart} ${String(roundedHour).padStart(
    2,
    '0',
  )}:00:00`;

  return roundedTime;
}

export function roundDownToHour(timeString: string): string {
  // 提取年月日时分秒部分
  const [datePart, timePart] = timeString.split(' ');

  // 提取小时和分钟部分
  const [hour, minute] = timePart.split(':').map(Number);

  // 向下取整到最接近的小时
  const roundedHour = hour;

  // 构建向下取整的时间字符串
  const roundedTime = `${datePart} ${String(roundedHour).padStart(
    2,
    '0',
  )}:00:00`;

  return roundedTime;
}
