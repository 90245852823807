import { RawUser } from 'core/models/userModel';

export interface UserBase {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export class UserModelBase implements UserBase {
  id: string;

  email: string;

  firstName: string;

  lastName: string;

  constructor(data: RawUser) {
    this.id = data._id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}
