/* eslint-disable react/require-default-props */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './style.less';
import SvgComponent from 'shared/components/svgComponent';
import { ApplicationState } from 'store/combineReducers';
import { baseUrlUser } from 'utils/api';
import { Image as AntImage } from 'antd';
import { getStoredTheme } from 'utils/localStorage';

interface LogoProps {
  dark?: boolean;
  svg?: boolean;
}

const logoDefault = `/static/images/vivalink-logo.png`;

export default function LogoNew({ dark, svg }: LogoProps): ReactElement {
  const { user } = useSelector((state: ApplicationState) => state.session);
  // const { settingInfo } = useSelector(
  //   (state: ApplicationState) => state.settingInfo,
  // );

  const settingInfo = JSON.parse(getStoredTheme());

  const logoPath = useMemo(() => {
    if (settingInfo?.theme != null && settingInfo?.theme?.logoImage != null) {
      return settingInfo.theme.logoImage;
    }
    return logoDefault;
  }, [settingInfo]);

  return (
    <Link
      to={baseUrlUser(user?.roles[0].name)}
      className={`logo-wrapper${svg ? '' : '-new'}${dark ? ' dark' : ''}`}
    >
      {svg ? (
        <SvgComponent iconName="logoNew" className="logo-source" />
      ) : (
        <AntImage width={180} height={30} src={logoPath} preview={false} />
      )}
      {/* <SvgComponent iconName="logoNew" className="logo-source" /> */}
      {/* <AntImage width={180} height={30} src={logoPath} preview={false} /> */}
    </Link>
  );
}

LogoNew.defaultProps = {
  dark: false,
};
