// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './style.less';
import SvgComponent from 'shared/components/svgComponent';
import { ApplicationState } from 'store/combineReducers';
import { baseUrlUser } from 'utils/api';

interface LogoProps {
  dark?: boolean;
}

export default function Logo({ dark }: LogoProps): ReactElement {
  const { user } = useSelector((state: ApplicationState) => state.session);

  return (
    <Link
      to={baseUrlUser(user?.roles[0].name)}
      className={`logo-wrapper${dark ? ' dark' : ''}`}
    >
      <SvgComponent iconName="logo" className="logo-source" />
    </Link>
  );
}

Logo.defaultProps = {
  dark: false,
};
