/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-continue */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// eslint-disable-next-line no-use-before-define
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConfigProvider, Menu as MenuAnt, MenuProps } from 'antd';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import { ProfileOutlined } from '@ant-design/icons';

import { config } from 'core/config';
import { ApplicationState } from 'store/combineReducers';
import { SvgComponent } from 'shared/components';
import { RolesEnum } from 'core/models';
import { useTranslation } from 'react-i18next';
import { getStoredTheme } from 'utils/localStorage';
import { MenuItems } from '../menuItems';
import './style.less';

const {
  profile,
  clinics,
  pmManagement,
  adminManagement,
  techManagement,
  physicianManagement,
  devices,
  patientManagement,
  monitoringProtocols,
  dashboard,
  ecgDataChunks,
  reports,
  settings,
  analysisSetting,
  otherSetting,
  groupManagement,
  historyView,
  patients,
  sites,
  studies,
  studiesManagement,
  researchersManagement,
  userManagement,
  templateManagement,
  emailTemplate,
  smsTemplate,
  dataAnalytics,

  rpm,
  rpmEcg,
  rpmRT,
  rpmRTList,
  addRpmPatient,

  orderManagement,

  tenant,
  tenantDevice,
} = config.paths;

const userListId = uniqid();
const pmListId = uniqid();
const sitesId = uniqid();
const studiesId = uniqid();
const studiesManagementId = uniqid();
const researchersId = uniqid();
const adminListId = uniqid();
const techListId = uniqid();
const physicianListId = uniqid();
const profileId = uniqid();
const assessmentsManagementId = uniqid();
const devicesId = uniqid();
const patientManagementId = uniqid();
const dashboardId = uniqid();
const monitoringProtocolsId = uniqid();
const ecgDataChunksId = uniqid();
const reportsId = uniqid();
const settingsId = uniqid();
const analysisSettingId = uniqid();
const otherSettingId = uniqid();
const groupManagementId = uniqid();
const historyId = uniqid();
const PatientsId = uniqid();
const templateManagementId = uniqid();
const emailTemplateId = uniqid();
const smsTemplateId = uniqid();
const dataAnalyticsId = uniqid();

const tenantDeviceId = uniqid();

const rpmId = uniqid();
const rpmEcgId = uniqid();
const rpmRTId = uniqid();

const orderManagementId = uniqid();

export interface MenuItem {
  id: string;
  title: string;
  path: string;
  subMenu?: MenuItem[];
  icon?: ReactElement<HTMLSpanElement>;
}

export default function Menu(): ReactElement {
  const history = useHistory();
  const user = useSelector((state: ApplicationState) => state.session?.user);
  const { t } = useTranslation();

  // const { settingInfo } = useSelector(
  //   (state: ApplicationState) => state.settingInfo,
  // );

  const settingInfo = JSON.parse(getStoredTheme());

  const theme = useMemo(() => {
    if (settingInfo != null && settingInfo?.theme != null) {
      const theme_: any = {};
      if (settingInfo.theme?.staticTextColor != null) {
        theme_.darkItemColor = settingInfo.theme.staticTextColor;
      }
      if (settingInfo.theme?.backgroundColor != null) {
        theme_.darkItemBg = settingInfo.theme.backgroundColor;
      }
      if (settingInfo.theme?.staticColor != null) {
        // theme_.darkSubMenuItemBg = settingInfo.theme.staticColor;
      }
      if (settingInfo.theme?.activatedColor != null) {
        theme_.darkItemSelectedBg = settingInfo.theme.activatedColor;
      }
      if (settingInfo.theme?.activatedTextColor != null) {
        theme_.darkItemSelectedColor = settingInfo.theme.activatedTextColor;
      }
      return {
        components: {
          Menu: theme_,
        },
      };
    }
    return {};
  }, [settingInfo]);

  const iconColor = useMemo(() => {
    if (settingInfo != null && settingInfo?.theme != null) {
      if (settingInfo.theme?.staticTextColor != null) {
        return { fill: settingInfo.theme?.staticTextColor };
      }
    }
    return {};
  }, [settingInfo]);

  const menuList: MenuItem[] = [
    {
      id: userListId,
      title: t('menu.userManagement'),
      path: `${userManagement}${pmManagement}`,
      icon: (
        <SvgComponent
          iconName="programManagers"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: dashboardId,
      title: t('menu.dashboard'),
      path: dashboard,
      icon: (
        <SvgComponent
          iconName="dashboard"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: rpmId,
      title: t('menu.rpm'),
      path: rpm,
      icon: (
        <SvgComponent
          iconName="rpm"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: rpmEcgId,
      title: t('menu.rpmEcg'),
      path: rpmEcg,
      icon: (
        <SvgComponent
          iconName="chart"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: rpmRTId,
      title: t('menu.rtRpm'),
      path: rpmRT,
      icon: (
        <SvgComponent
          iconName="rpmRT"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: PatientsId,
      title: t('menu.patients'),
      path: patients,
      icon: (
        <SvgComponent
          iconName="patients"
          width={18}
          height={18}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: sitesId,
      title: t('menu.sites'),
      path: sites,
      icon: (
        <SvgComponent
          iconName="programManagers"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: studiesId,
      title: t('menu.studies'),
      path: studies,
      icon: (
        <SvgComponent
          iconName="programManagers"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: studiesManagementId,
      title: t('menu.studiesManagement'),
      path: studiesManagement,
      icon: (
        <SvgComponent
          iconName="programManagers"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: researchersId,
      title: t('menu.researchers'),
      path: researchersManagement,
      icon: (
        <SvgComponent
          iconName="programManagers"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: patientManagementId,
      title: t('menu.patientManagement'),
      path: patientManagement,
      icon: (
        <SvgComponent
          iconName="patientManagement"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: adminListId,
      title: t('menu.admins'),
      path: adminManagement,
      icon: (
        <SvgComponent
          iconName="hospital"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: techListId,
      title: t('menu.technicians'),
      path: techManagement,
      icon: (
        <SvgComponent
          iconName="technicians"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: physicianListId,
      title: t('menu.physicians'),
      path: physicianManagement,
      icon: (
        <SvgComponent
          iconName="physicians"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: settingsId,
      title: t('menu.settings'),
      path: `${settings}`,
      icon: (
        <SvgComponent
          iconName="analysisSetting"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
      subMenu: [
        {
          id: analysisSettingId,
          title: t('menu.analysisSetting'),
          path: `${settings}${analysisSetting}`,
        },
        {
          id: groupManagementId,
          title: t('menu.groupManagement'),
          path: `${settings}${groupManagement}`,
        },
        {
          id: otherSettingId,
          title: t('menu.otherSetting'),
          path: `${settings}${otherSetting}`,
        },
      ],
    },
    {
      id: monitoringProtocolsId,
      title: t('menu.monitoringProtocol'),
      path: monitoringProtocols,
      icon: (
        <SvgComponent
          iconName="monitoringProtocols"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: templateManagementId,
      title: t('menu.templateManagement'),
      path: `${templateManagement}`,
      icon: (
        <SvgComponent
          iconName="templateManagement"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
      subMenu: [
        {
          id: emailTemplateId,
          title: t('menu.emailTemplate'),
          path: `${templateManagement}${emailTemplate}`,
        },
        {
          id: smsTemplateId,
          title: t('menu.smsTemplate'),
          path: `${templateManagement}${smsTemplate}`,
        },
      ],
    },
    {
      id: historyId,
      title: t('menu.history'),
      path: historyView,
      icon: (
        <SvgComponent
          iconName="history"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: devicesId,
      title: t('menu.devices'),
      path: devices,
      icon: (
        <SvgComponent
          iconName="devices"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: tenantDeviceId,
      title: t('menu.deviceManagement'),
      path: `${tenant}${tenantDevice}`,
      icon: (
        <SvgComponent
          iconName="devices"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: assessmentsManagementId,
      title: t('menu.clinics'),
      path: clinics,
      icon: (
        <SvgComponent
          iconName="hospital"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: reportsId,
      title: t('menu.reportArchive'),
      path: reports,
      icon: (
        <SvgComponent
          iconName="reports"
          width={22}
          height={22}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: dataAnalyticsId,
      title: t('menu.dataAnalytics'),
      path: dataAnalytics,
      icon: (
        <SvgComponent
          iconName="dataAnalytics"
          width={22}
          height={22}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: orderManagementId,
      title: t('menu.orderManagement'),
      path: orderManagement,
      icon: (
        <SvgComponent
          iconName="orderManagement"
          width={20}
          height={20}
          className="anticon"
          style={iconColor}
        />
      ),
    },
    {
      id: ecgDataChunksId,
      title: t('menu.ecgDataChunks'),
      path: ecgDataChunks,
      icon: <ProfileOutlined />,
    },
    {
      id: profileId,
      title: t('menu.profile'),
      path: profile,
      icon: (
        <SvgComponent
          iconName="profile"
          width={22}
          height={22}
          className="anticon"
          style={iconColor}
        />
      ),
    },
  ];

  const menuListTmp = useMemo(() => {
    if (user != null) {
      const rolesTmp = user.roles;
      const dataAnalyticsTmp = user.clinic.dataAnalytics;
      let isShowDataAnalytics = false;
      if (rolesTmp != null && dataAnalyticsTmp != null) {
        for (let i = 0; i < rolesTmp.length; i += 1) {
          const role = rolesTmp[i];
          if (role.name === RolesEnum.Admin && dataAnalyticsTmp.adminStatus) {
            isShowDataAnalytics = true;
          }
          if (
            role.name === RolesEnum.Physician &&
            dataAnalyticsTmp.physicianStatus
          ) {
            isShowDataAnalytics = true;
          }
          if (
            role.name === RolesEnum.ProgramManager &&
            dataAnalyticsTmp.programManagerStatus
          ) {
            isShowDataAnalytics = true;
          }
          if (
            role.name === RolesEnum.Researcher &&
            dataAnalyticsTmp.researcherStatus
          ) {
            isShowDataAnalytics = true;
          }
          if (
            role.name === RolesEnum.Technician &&
            dataAnalyticsTmp.technicianStatus
          ) {
            isShowDataAnalytics = true;
          }
          if (role.name === RolesEnum.ClinicalTrialCoordinator) {
            isShowDataAnalytics = true;
          }
        }
      }

      // const OrderManagementClinicIds = [
      //   'bms',
      //   'uattest_clinic',
      //   'ClinicA',
      //   'testUAT',
      //   'BMS-Demo',
      //   'CA058-P01',
      //   'CA058-002',
      //   'Vivalink-202301',
      // ];
      // let isShowOrderManagement = false;
      // for (let i = 0; i < OrderManagementClinicIds.length; i += 1) {
      //   const clinicId = OrderManagementClinicIds[i];
      //   if (user.clinic.title === clinicId) {
      //     isShowOrderManagement = true;
      //   }
      // }

      const rpmStatusTmp = user.clinic.rpmStatus;
      const rpmIds: string[] = [rpmId, rpmEcgId, rpmRTId];

      const list: MenuItem[] = [];
      for (let i = 0; i < menuList.length; i += 1) {
        const item = menuList[i];
        if (item.id === dataAnalyticsId && !isShowDataAnalytics) {
          continue;
        }
        if (!rpmStatusTmp && rpmIds.includes(item.id)) {
          continue;
        }
        // if (!isShowOrderManagement && item.id === orderManagementId) {
        //   continue;
        // }
        list.push(item);
      }
      return list;
    }
    return menuList;
  }, [menuList, user]);

  const hideMap = new Map([
    [addRpmPatient, rpmRT],
    [rpmRTList, rpmRT],
  ]);

  const findActive = useCallback((): [MenuItem | undefined, string[]] => {
    const pathname = history.location.pathname.split('/')?.[1];
    const pathAllname = history.location.pathname;
    for (let i = 0; i < menuListTmp.length; i += 1) {
      const item = menuListTmp[i];
      if (item.path?.includes(`/${pathname}`)) {
        if (item.subMenu != null && item.subMenu.length > 0) {
          for (let j = 0; j < item.subMenu.length; j += 1) {
            const itemSub = item.subMenu[j];
            if (pathAllname.startsWith(itemSub.path)) {
              return [itemSub, [item.id]];
            }
          }
        }
      }
    }

    let menuItem = menuListTmp.find((el) => {
      const value = hideMap.get(pathAllname);
      if (value != null) {
        return el.path?.includes(value);
      }
      return el.path?.includes(pathAllname);
    });

    if (menuItem == null) {
      menuItem = menuListTmp.find((el) => {
        return el.path?.includes(pathname);
      });
    }

    return [menuItem, []];
  }, [history.location.pathname]);

  const findUser = useCallback(
    () =>
      user?.roles?.map((item: any) => {
        if (item.name === 'Program Manager') {
          return 'ProgramManager';
        }
        if (item.name === 'VivaLNK Admin') {
          return 'VivaLNKAdmin';
        }
        if (item.name === 'Clinical Trial Coordinator') {
          return 'ClinicalTrialCoordinator';
        }
        return item.name;
      }),
    [user],
  );

  const [active, setActive] = useState<MenuItem | undefined>(findActive()[0]);

  function setSubMenuTitle() {
    const menuSubmenuList = document.getElementsByClassName('ant-menu-submenu');
    if (menuSubmenuList != null && menuSubmenuList.length > 0) {
      for (let i = 0; i < menuSubmenuList.length; i += 1) {
        const element = menuSubmenuList[i];
        const menuTitleList = element.getElementsByClassName(
          'ant-menu-title-content',
        );
        if (menuTitleList != null && menuTitleList.length > 0) {
          const title = menuTitleList[0].innerHTML;
          element.setAttribute('title', title);
        }
      }
    }
  }

  useEffect(() => {
    setActive(findActive()[0]);
    findUser();
    setSubMenuTitle();
  }, [findActive, history, findUser, user]);

  useEffect(() => {
    const selectedOld = document.getElementsByClassName(
      'ant-menu-item-selected',
    );
    const subSelectedOld = document.getElementsByClassName(
      'ant-menu-submenu-selected',
    );
    if (
      (selectedOld != null && selectedOld.length > 0) ||
      (subSelectedOld != null && subSelectedOld.length > 0)
    ) {
      let ele = null;
      if (selectedOld != null && selectedOld.length > 0) {
        ele = selectedOld[0];
      }
      if (subSelectedOld != null && subSelectedOld.length > 0) {
        ele = subSelectedOld[0];
      }
      const svgs = ele?.getElementsByTagName('svg');
      if (svgs != null && svgs.length > 0) {
        const svg = svgs[0];
        if (
          settingInfo != null &&
          settingInfo?.theme != null &&
          settingInfo.theme?.staticTextColor != null
        ) {
          svg.style.fill = settingInfo.theme?.staticTextColor;
        } else {
          svg.style.fill = '#ffffff';
        }
      }
    }
    setTimeout(() => {
      const selected = document.getElementsByClassName(
        'ant-menu-item-selected',
      );
      const subSelected = document.getElementsByClassName(
        'ant-menu-submenu-selected',
      );
      if (
        (selected != null && selected.length > 0) ||
        (subSelected != null && subSelected.length > 0)
      ) {
        let ele = null;
        if (selected != null && selected.length > 0) {
          ele = selected[0];
        }
        if (subSelected != null && subSelected.length > 0) {
          ele = subSelected[0];
        }
        const svgs = ele?.getElementsByTagName('svg');
        if (svgs != null && svgs.length > 0) {
          const svg = svgs[0];
          if (
            settingInfo != null &&
            settingInfo?.theme != null &&
            settingInfo.theme?.activatedTextColor != null
          ) {
            svg.style.fill = settingInfo.theme?.activatedTextColor;
          } else {
            svg.style.fill = '#ffffff';
          }
        }
      }
    }, 10);
  }, [
    document.getElementsByClassName('ant-menu-item-selected'),
    findActive,
    settingInfo,
  ]);

  const activeId =
    history.location.pathname.slice(0, 14) === '/studies-sites'
      ? studiesId
      : active?.id;

  const [subMenuKeys, setSubMenuKeys] = useState<string[]>(findActive()[1]);
  let subMenuFirstOpenStatus = true;

  const onOpenChange = useCallback((penKeys: string[]) => {
    if (!subMenuFirstOpenStatus) {
      setSubMenuKeys(penKeys);
    }
    subMenuFirstOpenStatus = false;
  }, []);

  return (
    <ConfigProvider theme={{ ...theme }}>
      <MenuAnt
        selectedKeys={[activeId || menuListTmp[0].id]}
        openKeys={subMenuKeys}
        theme="dark"
        mode="inline"
        onOpenChange={onOpenChange}
        className="yahuikeji-layout-menu"
        items={MenuItems({
          menuList: menuListTmp,
          roleName: findUser(),
        })}
        style={
          settingInfo?.theme?.backgroundColor == null
            ? {}
            : { backgroundColor: settingInfo.theme.backgroundColor }
        }
      />
    </ConfigProvider>
  );
}
