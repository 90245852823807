import {
  EcgEventType,
  EcgEventTypeModel,
  RawEcgEventType,
} from 'core/models/eventECGTypeModel';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  MonitoringProtocol,
  MonitoringProtocolModel,
  RawMonitoringProtocol,
} from 'core/models/monitoringProtocol/monitoringProtocol';

dayjs.extend(utc);

export interface ECGEventCombineFormData {
  patientId: string;
  patientStudieId: string;
  eventIds: string[];
  newEventColor: string;
  newEventTypeId: string;
}

interface ECGEventAnnotations {
  all: number;
  reviewed: number;
  unreviewed: number;
}

export interface ECGEvent {
  id: string;
  color: string;
  start: Dayjs;
  finish: Dayjs;
  flag: boolean;
  mark: boolean;
  annotations: ECGEventAnnotations;
  title: string;
  type: EcgEventType;
  monitoringProtocol?: MonitoringProtocol | string;
  readStatus: boolean;
  score: number;
}

export interface RawECGEvent {
  _id: string;
  color: string;
  start: string;
  finish: string;
  flag: boolean;
  mark: boolean;
  annotations: ECGEventAnnotations;
  title: string;
  type: RawEcgEventType | string;
  monitoringProtocol?: RawMonitoringProtocol | string;
  readStatus: boolean;
  score: number;
}

export class ECGEventModel implements ECGEvent {
  id: string;

  color: string;

  start: Dayjs;

  finish: Dayjs;

  flag: boolean;

  mark: boolean;

  annotations: ECGEventAnnotations;

  title: string;

  type: EcgEventType;

  monitoringProtocol?: MonitoringProtocol | string;

  readStatus: boolean;

  score: number;

  constructor(data: RawECGEvent) {
    this.id = data._id;
    this.color = data.color;
    this.start = dayjs.utc(
      dayjs.utc(data.start).format('YYYY-MM-DD HH:mm:ss.SSS'),
    );
    this.finish = dayjs.utc(
      dayjs.utc(data.finish).format('YYYY-MM-DD HH:mm:ss.SSS'),
    );
    this.flag = data.flag;
    this.mark = data.mark;
    this.annotations = data.annotations;
    this.title = data.title;
    if (typeof data.monitoringProtocol === 'string') {
      this.monitoringProtocol = data.monitoringProtocol;
    } else {
      this.monitoringProtocol =
        data.monitoringProtocol &&
        new MonitoringProtocolModel(data.monitoringProtocol);
    }

    if (typeof data.type === 'string') {
      throw Error('RawECGEvent type not populated');
    } else {
      this.type = new EcgEventTypeModel(data.type);
    }

    this.readStatus = data.readStatus;
    this.score = data.score;
  }
}
