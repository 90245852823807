import { AxiosResponse } from 'axios';
import { RawTimeZone, TimeZoneModel } from 'core/models/timeZoneModel';
import { setTimeZone } from 'reducers/timezone';
import { store } from 'store';

import { apiWrapper, axiosApi } from 'utils/api';

const getTimeZoneDict = async (): Promise<AxiosResponse<
  TimeZoneModel[]
> | void> => {
  const success = async (): Promise<AxiosResponse<TimeZoneModel[]>> => {
    const response = await axiosApi().get<RawTimeZone[]>(`/timezone`);
    const tizeZoneData = response.data?.map((item) => new TimeZoneModel(item));
    if (response) {
      store.dispatch(
        setTimeZone({
          timeZone: tizeZoneData,
        }),
      );
    }
    return {
      ...response,
      data: tizeZoneData,
    };
  };

  return apiWrapper<TimeZoneModel[]>({
    success,
  });
};
export default getTimeZoneDict;
