import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { handleUTCConvertTimeZoneTime } from 'utils/dayjsFormat';

dayjs.extend(utc);

export interface TimeZone {
  timeZoneOffset: number;
  timeZoneName: string;
  timeZoneDescription: string;
  timeZoneShortDescription: string;
  timeZoneIsDst: boolean;
  timeZoneDstStartTime?: string;
  timeZoneDstEndTime?: string;
}

export interface RawTimeZone {
  timeZoneOffset: number;
  timeZoneName: string;
  timeZoneDescription: string;
  timeZoneShortDescription: string;
  timeZoneIsDst: boolean;
  timeZoneDstStartTime?: string;
  timeZoneDstEndTime?: string;
}

export class TimeZoneModel implements TimeZone {
  timeZoneOffset: number;

  timeZoneName: string;

  timeZoneDescription: string;

  timeZoneShortDescription: string;

  timeZoneIsDst: boolean;

  timeZoneDstStartTime?: string;

  timeZoneDstEndTime?: string;

  constructor(data: RawTimeZone) {
    this.timeZoneOffset = data.timeZoneOffset;
    this.timeZoneName = data.timeZoneName;
    this.timeZoneDescription = data.timeZoneDescription;
    this.timeZoneShortDescription = data.timeZoneShortDescription;
    this.timeZoneIsDst = data.timeZoneIsDst;
    this.timeZoneDstStartTime = data.timeZoneDstStartTime;
    this.timeZoneDstEndTime = data.timeZoneDstEndTime;
  }
}

/**
 * timeZoneDescription
 * @param timeZoneList
 * @param timeZoneName 时区名称
 * @param prefix
 * @param suffix
 * @returns
 */
export const timeZoneDescription = (
  timeZoneList: TimeZoneModel[],
  timeZoneName: string,
  prefix?: string,
  suffix?: string,
): string => {
  for (let i = 0; i < timeZoneList.length; i += 1) {
    const item = timeZoneList[i];
    if (item.timeZoneName === timeZoneName) {
      let timeZoneShortDescription_ = item.timeZoneShortDescription;
      if (prefix != null) {
        timeZoneShortDescription_ = prefix + timeZoneShortDescription_;
      }
      if (suffix != null) {
        timeZoneShortDescription_ += suffix;
      }
      return timeZoneShortDescription_;
    }
  }
  return '';
};

/**
 * timeZoneDescription
 * @param timeZoneList
 * @param timeZoneName 时区名称
 * @param prefix
 * @param suffix
 * @returns
 */
export const timeZoneDescription2 = (
  timeZoneList: TimeZoneModel[],
  timeZoneName: string,
  prefix?: string,
  suffix?: string,
): string => {
  for (let i = 0; i < timeZoneList.length; i += 1) {
    const item = timeZoneList[i];
    if (item.timeZoneName === timeZoneName) {
      let timeZoneShortDescription_ = item.timeZoneShortDescription;
      const array = timeZoneShortDescription_.split('/');
      if (array != null && array.length >= 2) {
        const i1 = array[0];
        timeZoneShortDescription_ = i1;
      }
      if (prefix != null) {
        timeZoneShortDescription_ = prefix + timeZoneShortDescription_;
      }
      if (suffix != null) {
        timeZoneShortDescription_ += suffix;
      }
      return timeZoneShortDescription_;
    }
  }
  return '';
};

/**
 * timeZoneDescription
 * @param timeZoneList
 * @param timeZoneName 时区名称
 * @param prefix
 * @param suffix
 * @returns
 */
export const timeZoneDescription3 = (
  timeZoneList: TimeZoneModel[],
  timeZoneName: string,
  prefix?: string,
  suffix?: string,
): string => {
  for (let i = 0; i < timeZoneList.length; i += 1) {
    const item = timeZoneList[i];
    if (item.timeZoneName === timeZoneName) {
      let timeZoneDescription_ = item.timeZoneDescription;
      const array = timeZoneDescription_.split(')');
      if (array != null && array.length >= 2) {
        const i1 = array[1];
        timeZoneDescription_ = i1.trim();
      }
      if (prefix != null) {
        timeZoneDescription_ = prefix + timeZoneDescription_;
      }
      if (suffix != null) {
        timeZoneDescription_ += suffix;
      }
      return timeZoneDescription_;
    }
  }
  return '';
};

/**
 * timeZoneDstStartTime 夏令时开始时间
 * @param timeZoneList
 * @param timeZoneName 时区名称
 * @returns
 */
export const timeZoneDstStartTime = (
  timeZoneList: TimeZoneModel[],
  timeZoneName: string,
): dayjs.Dayjs | undefined => {
  for (let i = 0; i < timeZoneList.length; i += 1) {
    const item = timeZoneList[i];
    if (item.timeZoneName === timeZoneName) {
      if (item.timeZoneDstStartTime != null) {
        const x = handleUTCConvertTimeZoneTime(
          item.timeZoneOffset * 60,
          item.timeZoneDstStartTime,
        );
        return x;
        return dayjs.utc(item.timeZoneDstStartTime);
      }
    }
  }
  return undefined;
};

/**
 * timeZoneDstEndTime 夏令时结束时间
 * @param timeZoneList
 * @param timeZoneName 时区名称
 * @returns
 */
export const timeZoneDstEndTime = (
  timeZoneList: TimeZoneModel[],
  timeZoneName: string,
): dayjs.Dayjs | undefined => {
  for (let i = 0; i < timeZoneList.length; i += 1) {
    const item = timeZoneList[i];
    if (item.timeZoneName === timeZoneName) {
      if (item.timeZoneDstEndTime != null) {
        const x = handleUTCConvertTimeZoneTime(
          item.timeZoneOffset * 60,
          item.timeZoneDstEndTime,
        );
        return x;
        return dayjs.utc(item.timeZoneDstEndTime);
      }
    }
  }
  return undefined;
};
