/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
import { AxiosResponse } from 'axios';
import { SettingInfoModel } from 'core/models/settings/analysisSettingModel';
import { setSettingInfo } from 'reducers/settingInfo';
import { store } from 'store';

import { apiWrapper, axiosApi } from 'utils/api';
import { storeTheme } from 'utils/localStorage';

function getBase64FromUrl(url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getSetting = async (
  refreshStore: boolean,
): Promise<AxiosResponse<SettingInfoModel> | void> => {
  const success = async (): Promise<AxiosResponse<SettingInfoModel>> => {
    const response = await axiosApi().get<SettingInfoModel>(`/setting/info`);
    if (response) {
      const data_ = response.data;
      if (
        data_ != null &&
        data_.theme != null &&
        data_.theme.logoImage != null
      ) {
        const { logoImage } = data_.theme;
        const base64Data = await getBase64FromUrl(logoImage);
        if (data_.theme != null) {
          data_.theme.logoImage = base64Data as string;
        }
      }
      if (refreshStore) {
        store.dispatch(
          setSettingInfo({
            settingInfo: data_,
          }),
        );
      }

      const jsonString = JSON.stringify(data_);
      storeTheme(jsonString);
    }
    return {
      ...response,
      data: response.data,
    };
  };

  return apiWrapper<SettingInfoModel>({
    success,
  });
};
export default getSetting;
