export const emailValidateMessage = 'This field must be valid email';
export const requiredValidateMessage = 'This field is required';
export const maxLengthValidateMessage =
  'This field must be no longer than $max symbols';
export const passwordValidateMessage =
  'Password must contain at least 8 characters, 1 number, 1 upper case letter, 1 lower case letter and 1 special case character';
export const phoneValidateMessage = 'Invalid Phone Number';

export const maxLength = 50;

// export const passwordPattern = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1}).*$/;
export const passwordPattern = /^.*(?=.{8,30})(?=.*\d+)(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[!@#$%^&*?\\(\\)]+).*$/;

export const phonePattern = /(^[+]*(\d{10}|\d{11}|\d{12})$)|(^(\+\d-)*?\d{3}[- ]*?\d{3}[- ]*?\d{4}$)/;

export const subjectIdPattern = /^[\w\u4e00-\u9fa5-]{1,16}$/;

export const heightPattern = /^(?:[0-9]{1,3}(?:\.[0-9])?|999\.9)$/;

export const weightPattern = /^(?:[0-9]{1,3}(?:\.[0-9])?|999\.9)$/;

export const firstNameOrLastNamePattern = /^[a-zA-Z\u4e00-\u9fa5]{1,50}$/;
