import dayjs, { Dayjs } from 'dayjs';
import { RawPatient } from 'core/models/patientModel';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface PatientStudyNavigation {
  id: string;
  day: Dayjs;
  timeZoneOffset?: number;
}

export interface PatientStudyNavigationNew {
  id: string;
  day: string;
  timeZoneOffset?: number;
}

export interface RawPatientStudyNavigation {
  _id: string;
  day: string;
  timeZoneOffset?: number;
}

export interface PatientStudy {
  id: string;
  day: Dayjs;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  matchEventCount: number;
  annotationCount: number;
  neighbors?: PatientStudyNavigation[];
  patient?: any;
  days?: string[];
  neighborsDays?: PatientStudyNavigationNew[];
  dayStartTime?: string;
  dayEndTime?: string;
  timeZoneOffset: number;
}

export interface RawPatientStudy {
  _id: string;
  patient: RawPatient | string;
  day: Dayjs | string;
  startTime: Dayjs;
  endTime: Dayjs;
  matchEventCount: number;
  annotationCount: number;
  neighbors?: RawPatientStudyNavigation[];
  days?: RawPatientStudyNavigation[];
  dayStartTime?: string;
  dayEndTime?: string;
  timeZoneOffset: number;
}

export class PatientStudyModel implements PatientStudy {
  id: string;

  day: Dayjs;

  startTime: Dayjs | null;

  endTime: Dayjs | null;

  matchEventCount: number;

  annotationCount: number;

  neighbors?: PatientStudyNavigation[];

  patient?: any;

  neighborsDays?: PatientStudyNavigationNew[];

  days?: string[];

  dayStartTime?: string;

  dayEndTime?: string;

  timeZoneOffset: number;

  constructor(data: RawPatientStudy) {
    this.patient = data.patient;
    this.id = data._id;
    this.day = dayjs(data.day).utc();
    this.startTime = data.startTime ? dayjs(data.startTime).utc() : null;
    this.endTime = data.endTime ? dayjs(data.endTime).utc() : null;
    this.matchEventCount = data.matchEventCount;
    this.annotationCount = data.annotationCount;
    this.neighbors = data?.neighbors?.map((el) => ({
      id: el._id,
      day: dayjs(el.day).utc(),
      timeZoneOffset: el.timeZoneOffset,
    }));
    this.neighborsDays = data?.days?.map((el) => ({
      id: el._id,
      day: el.day,
      timeZoneOffset: el.timeZoneOffset,
    }));
    this.days = data?.days?.map((el) => el.day);
    this.dayStartTime = data.dayStartTime;
    this.dayEndTime = data.dayEndTime;
    this.timeZoneOffset = data.timeZoneOffset;
  }
}

export interface PatientStudyDayModel {
  id: string;
  patient: RawPatient;
  day: string;
  timeZoneOffset?: number;
  timeZoneName?: string;
  dayStartTime?: Dayjs;
  dayEndTime?: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  annotationCount: number;
  eventCount: number;
}
