export interface EcgEventType {
  id: string;
  _id?: string;
  code: string;
  name: string;
  color: string;
}

export interface RawEcgEventType {
  _id: string;
  code: string;
  name: string;
  color: string;
}

export class EcgEventTypeModel implements EcgEventType {
  id: string;

  code: string;

  name: string;

  color: string;

  constructor(data: RawEcgEventType) {
    this.id = data._id;
    this.code = data.code.toString();
    this.name = data.name;
    this.color = data.color;
  }
}
