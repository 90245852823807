/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, Col, Grid, Layout as LayoutAnt, Row } from 'antd';
import { Logo } from 'shared/components/logo';
import { Menu } from 'layout/components/menu';
import {
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from '@ant-design/icons';

import './style.less';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/combineReducers';
import LogoNew from 'shared/components/logo/LogoNew';
import { getStoredTheme } from 'utils/localStorage';

const { Sider } = LayoutAnt;
const { useBreakpoint } = Grid;

export default function SideBar(): ReactElement {
  const { lg, xl } = useBreakpoint();
  const [{ collapsed, collapsedWidth }, setCollapsed] = useState({
    collapsed: false,
    collapsedWidth: 0,
  });

  function handleCollapsed(width: number): VoidFunction {
    return (): void => {
      setCollapsed({
        collapsedWidth: width,
        collapsed: !collapsed,
      });
    };
  }

  const iconTriggerMobile = collapsed ? (
    <MenuUnfoldOutlined />
  ) : (
    <MenuFoldOutlined />
  );

  const iconTriggerDesktop = collapsed ? <RightOutlined /> : <LeftOutlined />;

  const mediaLgXl = lg && xl;

  useEffect(() => {
    setCollapsed({
      collapsedWidth: 0,
      collapsed: !mediaLgXl,
    });
  }, [mediaLgXl]);

  // const { settingInfo } = useSelector(
  //   (state: ApplicationState) => state.settingInfo,
  // );

  const settingInfo = JSON.parse(getStoredTheme());

  const theme = useMemo(() => {
    if (settingInfo != null && settingInfo?.theme != null) {
      return settingInfo.theme;
    }
    return null;
  }, [settingInfo]);

  return (
    <Sider
      collapsedWidth={collapsedWidth}
      collapsed={collapsed}
      className="full-height-page side-bar yahuikeji-layout-sideBar"
      trigger={null}
      style={{
        zIndex: mediaLgXl ? 100 : 102,
        // backgroundColor: theme != null ? `#c51770` : '',
      }}
      ref={(el) =>
        el &&
        el.style.setProperty(
          'background-color',
          theme?.backgroundColor != null ? theme?.backgroundColor : '',
          'important',
        )
      }
    >
      {!mediaLgXl && (
        <Button
          className="header-trigger"
          type="primary"
          onClick={handleCollapsed(0)}
          style={{
            backgroundColor: theme != null ? theme.backgroundColor : '',
          }}
        >
          {iconTriggerMobile}
        </Button>
      )}
      <Row style={{ height: '60px' }} justify="center" align="middle">
        <LogoNew />
      </Row>
      <div className="yahuikeji-menu">
        <div className="box">
          <Menu />
        </div>
        {mediaLgXl && (
          <div
            className="footer"
            style={{
              backgroundColor: theme != null ? theme.backgroundColor : '',
            }}
          >
            <Button
              type="text"
              onClick={handleCollapsed(80)}
              className="bottom-trigger"
              style={{
                backgroundColor: theme != null ? theme.backgroundColor : '',
              }}
            >
              {iconTriggerDesktop}
            </Button>
          </div>
        )}
      </div>
      {/* <Row className="yahuikeji-row-menu">
        <Col span={24}>
          <Menu />
        </Col>
        {mediaLgXl && (
          <Col span={24} style={{ alignSelf: 'flex-end' }}>
            <Button
              type="primary"
              onClick={handleCollapsed(80)}
              className="bottom-trigger"
            >
              {iconTriggerDesktop}
            </Button>
          </Col>
        )}
      </Row> */}
    </Sider>
  );
}
