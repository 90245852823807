import { History } from 'history';
import { AxiosError, AxiosResponse } from 'axios';

import {
  getStoredAccessToken,
  removeStoredAccessToken,
  removeStoredPaginationConfigPageSize,
  removeStoredRpmPaginationConfigPageSize,
  removeStoredUserLanguage,
  storeAccessToken,
} from 'utils/localStorage';
import { config } from 'core/config';
import { store } from 'store';
import { removeUser } from 'reducers/session';
import { SignInFormData } from 'pages/signIn/SignIn';
import { RawUser } from 'core/models/userModel';
import { apiWrapper, axiosApi, errorNotificationWith } from 'utils/api';
import getTimeZoneDict from 'core/service/timeZone.service';
import getSetting from 'core/service/setting.service';
import { changeUserLanguageTmp } from 'i18n';

interface LoginUserResponse {
  accessToken: string;
  user: RawUser;
  message: string;
}

export const loginUser = async ({
  email,
  password,
}: SignInFormData): Promise<AxiosResponse<LoginUserResponse> | void> => {
  const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
    const response = await axiosApi().post<LoginUserResponse>(
      '/authentication',
      {
        strategy: 'local',
        email,
        password,
      },
    );
    const { accessToken, user } = response.data;
    storeAccessToken(accessToken);
    if (user?.language != null) {
      changeUserLanguageTmp(user?.language);
    }
    // getTimeZoneDict();
    await getSetting(true);
    return response;
  };

  const error = (e: AxiosError): AxiosResponse | void => {
    if (e.response?.status === 401) {
      e.response.data.message = 'Invalid Email or Password';
      return e.response;
    }
    if (e.response?.status === 403) {
      e.response.data.message =
        'This account is deactivated. Please contact the VivaLNK support';
      return e.response;
    }
    errorNotificationWith({
      message: e.response?.statusText || e.message,
    });
    return undefined;
  };

  return apiWrapper<LoginUserResponse>({
    success,
    error,
  });
};

export const quickLoginApi = async (
  vcloudToken: string,
): Promise<AxiosResponse<any> | void> => {
  const success = async (): Promise<AxiosResponse<any>> => {
    const response = await axiosApi().post<any>('/token/exchange', {
      vcloudToken,
    });
    const { token } = response.data?.data;
    storeAccessToken(token);
    return response;
  };
  return apiWrapper<any>({
    success,
  });
};

export const logoutUser = (history?: History): void => {
  if (getStoredAccessToken()) {
    store.dispatch(removeUser());
    removeStoredPaginationConfigPageSize();
    removeStoredRpmPaginationConfigPageSize();
    removeStoredAccessToken();
  }
  removeStoredUserLanguage();
  if (window.location.pathname !== config.paths.signIn) {
    setTimeout(() => {
      if (history) {
        history.push(config.paths.signIn);
      } else {
        window.location.replace(config.paths.signIn);
      }
    }, 750);
  }
};
