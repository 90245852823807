import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './combineReducers';
import preloadedState from './preloadedState';

const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const middlewares =
  isChrome &&
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
    : compose(applyMiddleware(thunk));

export const store = createStore(reducers, preloadedState, middlewares);

export default store;
