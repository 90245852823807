/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';
import svgSource, { SvgSourceItem } from 'theme/svgSource';

interface SvgComponentProps {
  iconName: string;
  className?: string;
  [key: string]: string | number | undefined | any;
}

export default function SvgComponent({
  iconName,
  className = '',
  ...rest
}: SvgComponentProps): ReactElement {
  const classNameArr = ['icon'];
  const otherAttr = { ...rest };

  classNameArr.push(`icon_${iconName}`);
  classNameArr.push(className);
  delete otherAttr.name;
  delete otherAttr.className;

  const [svgElem, setSvgElem] = useState<SvgSourceItem>();

  useEffect(() => {
    if (iconName != null && iconName !== '' && svgSource != null) {
      for (let i = 0; i < svgSource?.length ?? 0; i += 1) {
        const svgItem = svgSource[i];
        if (svgItem.id === iconName) {
          setSvgElem(svgItem);
        }
      }
    }
  }, [iconName, svgSource]);

  if (svgElem == null) {
    return <></>;
  }

  return (
    <svg
      className={classNameArr.join(' ').trim()}
      {...otherAttr}
      key={svgElem.id}
      id={svgElem.id}
      viewBox={svgElem.viewBox}
      dangerouslySetInnerHTML={{ __html: svgElem.source }}
    />
  );

  return (
    <svg
      className={classNameArr.join(' ').trim()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherAttr}
    >
      <use xlinkHref={`#${iconName}`} />
    </svg>
  );
}

SvgComponent.defaultProps = {
  className: '',
};
