export interface DashboardItem {
  id: string;
  patientId: string;
  patientStudieId: string;
  patientFirstName: string;
  patientLastName: string;
  monitoringProtocol: string;
  startTime: string;
  endTime: string;
  matchEventCount: string;
  messageType: number;
  patientInternalId: string;
  patientSubjectId: string;
  deviceIdentifier: string;
}

export class DashboardItemModel implements DashboardItem {
  id: string;

  monitoringProtocol: string;

  matchEventCount: string;

  endTime: string;

  startTime: string;

  messageType: number;

  patientId: string;

  patientStudieId: string;

  patientFirstName: string;

  patientLastName: string;

  patientInternalId: string;

  patientSubjectId: string;

  deviceIdentifier: string;

  constructor(data: DashboardItem) {
    this.id = data.id;
    this.patientId = data.patientId;
    this.patientFirstName = data.patientFirstName;
    this.patientLastName = data.patientLastName;
    this.patientStudieId = data.patientStudieId;
    this.monitoringProtocol = data.monitoringProtocol;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.matchEventCount = data.matchEventCount || '-';
    this.messageType = data.messageType;
    this.patientInternalId = data.patientInternalId;
    this.patientSubjectId = data.patientSubjectId;
    this.deviceIdentifier = data.deviceIdentifier || '-';
  }
}
