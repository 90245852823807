import { Action, createActions, handleActions } from 'redux-actions';

export interface LoaderState {
  isLoading: number;
  tip?: string;
}

interface LoaderPayload {
  tip?: string;
}

interface LoaderReducer {
  [action: string]: (
    state: LoaderState,
    action?: Action<LoaderPayload>,
  ) => LoaderState;
}

export const defaultState: LoaderState = {
  isLoading: 0,
  tip: 'loading',
};

const prefix = {
  prefix: 'LOADER', // String used to prefix each type
  namespace: '--', // Separator between prefix and type.  Default: `/`
};

export const { show: showLoader, hide: hideLoader } = createActions(
  {
    SHOW: (state) => state,
    HIDE: (state) => state,
  },
  prefix,
);

const loaderReducer = {
  SHOW: (
    state: LoaderState,
    { payload }: Action<LoaderPayload>,
  ): LoaderState => {
    return {
      ...state,
      isLoading: state.isLoading + 1,
      tip: payload?.tip != null ? payload.tip : 'loading',
    };
  },
  HIDE: (
    state: LoaderState,
    { payload }: Action<LoaderPayload>,
  ): LoaderState => {
    return {
      ...state,
      isLoading: state.isLoading === 0 ? 0 : state.isLoading - 1,
      tip: payload?.tip != null ? payload.tip : 'loading',
    };
  },
} as LoaderReducer;

export const loader = handleActions<LoaderState>(
  loaderReducer,
  defaultState,
  prefix,
);
