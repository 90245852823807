import { Action, createActions, handleActions } from 'redux-actions';

import { SettingInfoModel } from 'core/models/settings/analysisSettingModel';

export interface SettingInfoState {
  settingInfo: SettingInfoModel;
}

export interface SettingInfoPayload {
  settingInfo?: SettingInfoModel;
}

export interface SettingInfoReducer {
  [action: string]: (
    state: SettingInfoState,
    action?: Action<SettingInfoPayload>,
  ) => SettingInfoState;
}

export const defaultState: SettingInfoState = {
  settingInfo: {},
};

const prefix = {
  prefix: 'SETTINGINFO', // String used to prefix each type
  namespace: '--', // Separator between prefix and type.  Default: `/`
};

export const { setSettingInfo } = createActions<SettingInfoPayload>(
  {
    SET_SETTING_INFO: (settingInfo) => {
      return settingInfo;
    },
  },
  prefix,
);

const settingInfoReducer = {
  SET_SETTING_INFO: (
    state: SettingInfoState,
    { payload: { settingInfo } }: Action<SettingInfoPayload>,
  ): SettingInfoState => {
    return {
      ...state,
      settingInfo: settingInfo || {},
    };
  },
} as SettingInfoReducer;

export const settingInfo = handleActions<SettingInfoState>(
  settingInfoReducer,
  defaultState,
  prefix,
);
