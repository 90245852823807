import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { RawUser } from 'core/models/userModel';
import { ECGEvent, RawECGEvent } from 'core/models/eventECGModel';
import { UserBase, UserModelBase } from 'core/models/userModelBase';

dayjs.extend(utc);
// eslint-disable-next-line no-shadow
export enum EcgAnnotationStatuses {
  unreviewed = 'unreviewed',
  reviewed = 'reviewed',
}

export interface EcgAnnotationFormData {
  patient: string;
  ecgEvent: string;
  description?: string;
  maxMv: number;
  minMv: number;
  start: number;
  finish: number;
  coords?: {
    hr?: number;
  };
}

export interface EcgAnnotation {
  id: string;
  ecgEvent?: Partial<ECGEvent> | string;
  patient?: string;
  jumpStart?: number;
  jumpFinish?: number;
  latestECGStart?: number;
  latestECGFinish?: number;
  latestECG?: boolean;
  creator: UserBase;
  status: EcgAnnotationStatuses;
  description: string;
  maxMv?: number;
  minMv?: number;
  start?: Dayjs | string | Date;
  finish?: Dayjs | string | Date;
  createdAt?: Dayjs | string | Date;
  coords?: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    xStart?: number;
    yStart?: number;
    draw?: boolean;
    hr?: number;
  };
}

export interface RawEcgAnnotation {
  _id: string;
  ecgEvent?: RawECGEvent;
  patient?: string;
  jumpStart?: number;
  jumpFinish?: number;
  creator: RawUser;
  status: EcgAnnotationStatuses;
  description: string;
  maxMv?: number;
  minMv?: number;
  start: Date;
  finish: Date;
  createdAt: Date;
  coords: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    hr?: number;
  };
}

export class EcgAnnotationModel implements EcgAnnotation {
  id: string;

  ecgEvent?: Partial<ECGEvent> | string;

  patient?: string;

  jumpStart?: number;

  jumpFinish?: number;

  creator: UserBase;

  status: EcgAnnotationStatuses;

  description: string;

  maxMv?: number;

  minMv?: number;

  start?: Dayjs;

  finish?: Dayjs;

  createdAt?: Dayjs;

  coords?: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    hr?: number;
  };

  constructor(data: RawEcgAnnotation) {
    this.id = data._id;
    this.ecgEvent = {
      id: data.ecgEvent?._id,
    };
    this.patient = data.patient;
    this.jumpStart = data.jumpStart;
    this.jumpFinish = data.jumpFinish;
    this.creator = new UserModelBase(data.creator);
    this.status = data.status;
    this.description = data.description;
    this.maxMv = data.maxMv;
    this.minMv = data.minMv;
    this.start = dayjs(data.start).utc();
    this.finish = dayjs(data.finish).utc();
    this.createdAt = dayjs(data.createdAt).utc();
    this.coords = data.coords;
  }
}
