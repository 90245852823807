// eslint-disable-next-line no-use-before-define
import React, { ReactElement, ReactNode } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import './style.less';

import { ApplicationState } from 'store/combineReducers';
import { t } from 'i18next';

export interface LoaderProps {
  children?: ReactElement | ReactNode;
  isLoading?: boolean;
  tip?: string;
}

export default function Loader({
  children,
  isLoading: isLoadingProps,
}: LoaderProps): ReactElement {
  const { isLoading, tip } = useSelector(
    (state: ApplicationState) => state.loader,
  );

  const tip_ = tip != null ? t(tip) : t('loading');

  return (
    <Spin
      tip={tip_}
      size="default"
      spinning={!!(isLoading || isLoadingProps)}
      wrapperClassName="loader-wrap"
    >
      {children}
    </Spin>
  );
}
