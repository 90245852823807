// eslint-disable-next-line no-shadow
export enum RolesEnum {
  VivaLNKAdmin = 'VivaLNK Admin',
  ProgramManager = 'Program Manager',
  Admin = 'Admin',
  Technician = 'Technician',
  Physician = 'Physician',
  Researcher = 'Researcher',
  ClinicalTrialCoordinator = 'Clinical Trial Coordinator',
}

export type RoleName = keyof typeof RolesEnum;

export interface Role {
  id: string;
  name: string;
  key?: RoleName;
  permissions?: string[];
}

export interface RawRole {
  _id: string;
  name: string;
  key?: RoleName;
  permissions?: string[];
}

export class RoleModel implements Role {
  id: string;

  name: string;

  key?: RoleName;

  constructor(data: RawRole) {
    this.id = data._id;
    this.name = data.name;
    this.key = data?.key || (data.name.replace(' ', '') as RoleName);
  }
}
