// import { PatientBase, PatientModelBase } from 'core/models/patientModelBase';
// import { RawPatient } from 'core/models/patientModel';
// import {
//   MonitoringProtocol,
//   MonitoringProtocolModel,
//   RawMonitoringProtocol,
// } from 'core/models/monitoringProtocol';

export interface ReportsItem {
  id: string;
  patient: string;
  patientFirstName: string;
  patientLastName: string;
  physicianName: string;
  enrollmentPeriod: string;
  enrollmentStart: string;
  enrollmentEnd: string;
  patientInternalId: string;
  patientTimeZoneOffset: number;
  createdAt: string;
  updatedAt: string;
  pdfFilePath?: string;
  version?: number;
  draft: boolean;
  status: number; // 报告状态 （0:创建中 1:创建失败 2:创建成功 3:已归档） version=3时生效暂时
  requestParameters: string;
  isHaveCsvData: boolean;
}

export interface RawReportsItem {
  _id: string;
  analysisTime: string;
  createdAt: string;
  deleteDate: string;
  deleted: boolean;
  enrollmentDaySum: number;
  enrollmentEnd: string;
  enrollmentPeriod: string;
  enrollmentStart: string;
  // events: string;
  findings: string;
  monitoringDaySum: number;
  monitoringEnd: string;
  monitoringProtocols: string[];
  monitoringStart: string;
  patient: string;
  patientFirstName: string;
  patientLastName: string;
  physicianName: string;
  updatedAt: string;
  patientInternalId: string;
  patientSubjectId: string;
  patientTimeZoneOffset: number;
  pdfFilePath?: string;
  version?: number;
  draft: boolean;
  status: number;
  requestParameters: string;
  isHaveCsvData: boolean;

  // identifier: string;
  // day: string;
  // patient: RawPatient;
  // monitoringProtocol: RawMonitoringProtocol;
  // chartFinish: string;
  // chartStart: string;
  // createdAt: string;
  // events: number;
  // flaggedEvents: number;
  // matchedEvents: number;
  // eventsFinish: string;
  // eventsStart: string;
  // flaggedEventsFinish: string;
  // flaggedEventsStart: string;
  // matchedEventsFinish: string;
  // matchedEventsStart: string;
}

export class ReportsItemModel implements ReportsItem {
  id: string;

  patient: string;

  patientFirstName: string;

  patientLastName: string;

  physicianName: string;

  enrollmentPeriod: string;

  enrollmentStart: string;

  enrollmentEnd: string;

  patientInternalId: string;

  patientSubjectId: string;

  patientTimeZoneOffset: number;

  createdAt: string;

  updatedAt: string;

  pdfFilePath?: string;

  version?: number;

  draft: boolean;

  status: number;

  requestParameters: string;

  isHaveCsvData: boolean;

  constructor(data: RawReportsItem) {
    this.id = data._id;
    this.patient = data.patient;
    this.patientFirstName = data.patientFirstName;
    this.patientLastName = data.patientLastName;
    this.physicianName = data.physicianName;
    this.enrollmentPeriod = data.enrollmentPeriod;
    this.enrollmentStart = data.enrollmentStart;
    this.enrollmentEnd = data.enrollmentEnd;
    this.patientInternalId = data.patientInternalId;
    this.patientSubjectId = data.patientSubjectId;
    this.patientTimeZoneOffset = data.patientTimeZoneOffset;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.pdfFilePath = data.pdfFilePath;
    this.version = data.version;
    this.draft = data.draft;
    this.status = data.status;
    this.requestParameters = data.requestParameters;
    this.isHaveCsvData = data.isHaveCsvData;
  }
}
