import { PatientBase, PatientModelBase } from 'core/models/patientModelBase';
import { Patient, PatientModel, RawPatient } from 'core/models/patientModel';

// eslint-disable-next-line no-shadow
export enum DeviceEnum {
  ECG = 'ECG',
  SpO2 = 'SpO2',
  BP = 'BP',
  Temperature = 'Temperature',
  HR = 'HR',
  HRV = 'HRV',
  RR = 'RR',
  Activity = 'Activity',
  CGM = 'CGM',
  EcgEventCount = 'EcgEventCount',
  AfibAndPvcAndPacCount = 'AfibAndPvcAndPacCount',
}

export interface DeviceSortData {
  key: string;
  order: string;
  sortType: 'ascend' | 'descend' | null;
}

export interface DeviceType {
  _id: string;
  code: string;
  name: string;
}
export interface Device {
  id: string;
  identifier: string;
  patient?: Patient;
  siteId?: string;
  siteName?: string;
  type?: string;
  createdAt?: string;
  kitId?: string;
}

export interface PatientDevices {
  deviceId: string;
  siteId?: string;
  deviceName?: string;
  deviceTypeName?: PatientBase;
  note?: string;
  sessionId?: string;
  createdAt?: string;
  kitId?: string;
}

export interface RawDevice {
  _id: string;
  identifier: string;
  patient?: RawPatient | string;
  siteId?: string;
  siteName?: string;
  type?: string;
  typeName?: string;
  time?: string;
  note?: string;
  sessionId?: string;
  createdAt?: string;
  kitId?: string;
}

export const DEVICE_TITLE = 'Device';

export class DeviceModel implements Device {
  id: string;

  identifier: string;

  patient?: Patient;

  siteId?: string;

  siteName?: string;

  type?: string;

  note?: string;

  sessionId?: string;

  createdAt?: string;

  kitId?: string;

  constructor(data: RawDevice) {
    this.id = data._id;
    this.identifier = data.identifier;
    if (typeof data.patient === 'string') {
      throw Error('RawDevice not populated');
    } else {
      this.patient = data.patient && new PatientModel(data.patient);
    }
    this.siteId = data.siteId;
    this.siteName = data.siteName;
    this.type = data.type;
    this.note = data.note;
    this.sessionId = data.sessionId;
    this.createdAt = data.createdAt;
    this.kitId = data.kitId;
  }
}

export interface DeviceFormData {
  identifier: string;
  type?: string;
  site?: string;
}
