import { Action, createActions, handleActions } from 'redux-actions';

import { User } from 'core/models/userModel';
import { getStoredAccessToken } from 'utils/localStorage';

export interface SessionState {
  isAuth: boolean;
  user?: User;
}

export interface SessionPayload {
  user?: User;
}

export interface SessionReducer {
  [action: string]: (
    state: SessionState,
    action?: Action<SessionPayload>,
  ) => SessionState;
}

export const defaultState: SessionState = {
  isAuth: !!getStoredAccessToken(),
};

const prefix = {
  prefix: 'SESSION', // String used to prefix each type
  namespace: '--', // Separator between prefix and type.  Default: `/`
};

export const { setUser, removeUser } = createActions<SessionPayload>(
  {
    SET_USER: (user) => user,
    REMOVE_USER: (state) => state,
  },
  prefix,
);

const sessionReducer = {
  SET_USER: (
    state: SessionState,
    { payload: { user } }: Action<SessionPayload>,
  ): SessionState => ({
    ...state,
    isAuth: true,
    user,
  }),
  REMOVE_USER: (state: SessionState): SessionState => ({
    ...state,
    isAuth: false,
  }),
} as SessionReducer;

export const session = handleActions<SessionState>(
  sessionReducer,
  defaultState,
  prefix,
);
