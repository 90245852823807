/* eslint-disable no-loop-func */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';

import { rbacProcess } from 'shared/components/privateRouter';
import { RoleName, RolesEnum } from 'core/models/roleModel';
import userAdd from 'pages/usersManagement/pages/userAdd';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/combineReducers';
import { config } from 'core/config';
import { t } from 'i18next';
import { MenuItem } from '../menu/Menu';

const { SubMenu, Item } = Menu;

const {
  studies,
  settings,
  groupManagement,
  analysisSetting,
  otherSetting,
  monitoringProtocols,
  studiesManagement,
} = config.paths;

const isAccept = (item: MenuItem, roleName?: RoleName): boolean => {
  if (item.path === '' && item.subMenu && item.subMenu?.length > 0) {
    return true;
  }
  return !!roleName && rbacProcess(`${item.path}`, roleName);
};

export interface MenuItemProps {
  menuList: MenuItem[];
  roleName?: any;
}

export default function MenuItems({
  menuList,
  roleName,
}: MenuItemProps): MenuProps['items'] {
  const { user } = useSelector((state: ApplicationState) => state.session);

  const menuListNew: MenuItem[] = [];
  let arr: any[] = [];
  for (let i = 0; i < menuList.length; i++) {
    if (user?.site != null) {
      for (let j = 0; j < roleName?.length; j++) {
        const name = roleName[j];
        const { path } = menuList[i];
        if (path !== monitoringProtocols) {
          menuListNew.push(menuList[i]);
        }
      }
    } else if (!user?.clinic?.studiesStatus) {
      const { path } = menuList[i];
      if (path !== studiesManagement && path !== studies) {
        menuListNew.push(menuList[i]);
      }
    } else {
      menuListNew.push(menuList[i]);
    }
  }
  for (let i = 0; i < menuListNew.length; i++) {
    const subMenuList: MenuItem[] = [];
    const item = menuListNew[i];
    const acceptMenuList: MenuItem[] = [];
    for (let j = 0; j < roleName?.length; j++) {
      if (item.subMenu != null && item.subMenu.length > 0) {
        for (let n = 0; n < item.subMenu.length; n += 1) {
          const subMenu_ = item.subMenu[n];
          if (isAccept(subMenu_, roleName[j])) {
            if (user?.clinic.rpmStatus === false) {
              if (subMenu_.path === `${settings}${groupManagement}`) {
                continue;
              }
            }
            if (user?.site != null) {
              if (
                subMenu_.path === `${settings}${analysisSetting}` ||
                subMenu_.path === `${settings}${otherSetting}`
              ) {
                continue;
              }
            }
            subMenuList.push(subMenu_);
          }
        }
      }
      if (isAccept(item, roleName[j])) {
        if (user?.clinic.rpmStatus === false && user?.site != null) {
          if (item.path === settings) {
            continue;
          }
        }
        acceptMenuList.push(item);
      }
    }
    if (acceptMenuList.length > 0) {
      acceptMenuList.forEach((item_) => {
        if (subMenuList != null && subMenuList.length > 0) {
          item_.subMenu = subMenuList;
        } else {
          item_.subMenu = undefined;
        }
        arr.push(item_);
      });
    }
  }
  arr = arr?.filter((item: any, i) => {
    if (item.title === t('menu.sites') && !user?.clinic.sitesStatus) {
      return false;
    }
    return true;
  });
  arr = arr?.filter((item: any, i) => {
    if (
      (item.title === t('menu.studies') ||
        item.title === t('menu.studiesManagement')) &&
      !user?.clinic.studiesStatus
    ) {
      return false;
    }
    return true;
  });

  return Array.from(new Set(arr)).map((item) => {
    if (item.subMenu != null && item.subMenu.length > 0) {
      const menuItem = {
        label: item.title,
        key: item.id,
        icon: item.icon,
        disabled: false,
        children: [],
      };
      const subMenuItemList = item.subMenu.map((item2: MenuItem) => {
        const subMenuItem = {
          label: (
            <Link to={item2.path} title={item2.title}>
              {item2.title}
            </Link>
          ),
          key: item2.id,
          disabled: false,
        };
        return subMenuItem;
      });
      menuItem.children = subMenuItemList;
      return menuItem;
    }
    const menuItem = {
      label: (
        <Link to={item.path} title={item.title}>
          {item.title}
        </Link>
      ),
      key: item.id,
      icon: item.icon,
      disabled: false,
    };
    return menuItem;
  });
}
