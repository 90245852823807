import { RawPatient } from 'core/models/patientModel';

export interface PatientBase {
  id: string;
  firstName: string;
  lastName: string;
  name?: string;
}

export class PatientModelBase implements PatientBase {
  id: string;

  firstName: string;

  lastName: string;

  name?: string;

  constructor(data: RawPatient) {
    this.id = data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.name = data.name;
  }
}
