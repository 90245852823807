module.exports = {
  // Brand colors
  '@white': '#fff',
  '@black': '#000',
  '@blue-2': '#bae7ff',
  '@blue-8': '#294B76',
  '@blue-9': '#003a8c',
  '@blue-10': '#233151',

  // Colors

  // Layout
  '@layout-header-background': '@white',
  '@layout-sider-background': '@blue-8',
  '@layout-header-height': '60px',

  // Menu
  '@menu-item-vertical-margin': '0',
  // Menu dark
  '@menu-dark-bg': '@blue-8',

  // Dropdown
  '@dropdown-edge-child-vertical-padding': '0',

  // Border color
  '@border-color-base': 'hsv(0, 0, 85%)', // base border outline a component
  '@border-color-split': 'hsv(0, 0, 94%)', // split border inside a component
  '@border-color-inverse': '@white',
  '@border-width-base': '1px', // width of the border for a component
  '@border-style-base': 'solid', // style of a components border

  '@text-color-secondary-dark': 'fade(@white, 65%)',

  '@text-color': 'fade(@black, 85%)',

  // Text
  //  '@font-family':
  //    "'Gibson', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  //  '@text-color': 'fade(#000, 65%)',
  //  '@text-color-secondary': 'fade(#000, 45%)',
  //  '@font-size-base': '16px',
  //
  //  '@border-color-base': '@gray-light',
  //  '@border-color-split': '@gray-light',
  //  '@border-radius-base': '0',
  //  '@border-radius-sm': '0',
  //  '@outline-width': '0',
  //
  //  '@btn-default-border': '@gray-dark',
  //  '@btn-primary-bg': '@orange',
  //  '@btn-shadow': '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
  //  '@btn-primary-shadow':
  //    '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
  //
  //  '@radio-button-bg': 'transparent',
  //  '@radio-button-checked-bg': 'transparent',
  //
  //  '@form-item-margin-bottom': '16px',
  //  '@input-height-lg': '36px',
  //  '@input-hover-border-color': '#737373',
  //
  //  '@progress-radius': '0',
  //
  //  '@table-header-bg': '@gray-light',
  //  '@table-row-hover-bg': '@gray-light',
  //  '@table-padding-vertical': '15px',
  //
  //  '@tabs-card-gutter': '-1px',
  //  '@tabs-card-tab-active-border-top': '2px solid @orange',
  //  '@breadcrumb-base-color': '@text-color',
  //  '@breadcrumb-last-item-color': '@text-color-secondary',
  //
  //  '@slider-handle-border-width': '1px',
  //  '@slider-handle-shadow': '1px 1px 4px 0 rgba(0,0,0,.13)',
  //  '@slider-track-background-color': '@gray',
  //  '@slider-track-background-color-hover': '@gray',
  //  '@slider-handle-color': '@gray-light',
  //  '@slider-handle-color-hover': '@gray-light',
  //  '@slider-handle-color-focus': '@gray-light',
  //  '@slider-handle-color-focus-shadow': 'transparent',
  //  '@slider-handle-color-tooltip-open': '#ddd',
  //
  //  '@alert-success-border-color': '#dff4e5',
  //  '@alert-success-bg-color': '#dff4e5',
  //  '@alert-info-border-color': '#e5f3ff',
  //  '@alert-info-bg-color': '#e5f3ff',
  //  '@alert-warning-border-color': '#fff7db',
  //  '@alert-warning-bg-color': '#fff7db',
  //  '@alert-error-border-color': '#fcebea',
  //  '@alert-error-bg-color': '#fcebea',
  //
  //  '@layout-body-background': '@default-gray',
  //  '@layout-header-background': '@orange',
  //  '@layout-sider-background': '@gray',
  //
  //  '@card-background': '@white',
  //
  //  '@menu-dark-item-active-bg': '@orange',
  //  '@menu-dark-bg': '@gray',
  //  '@menu-dark-submenu-bg': '@gray',
  //  '@switch-color': '@orange',
  //  // Table
  //  '@table-selected-row-bg': 'darken(@background-color-light, 20%)',
};
