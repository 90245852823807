// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import 'theme/main.less';
import { Provider } from 'react-redux';

import { SvgSprite } from 'shared/components';
import { Routes } from 'routes';
import { svgSource } from 'theme';
import { store } from 'store';
import './i18n';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import 'dayjs/locale/zh-cn';
import { getStoredUserLanguage } from 'utils/localStorage';

function App(): ReactElement {
  const language = getStoredUserLanguage();
  let locale = enUS;
  if (language === 'zh-CN') {
    locale = zhCN;
  }

  return (
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <Routes />
        <SvgSprite symbols={svgSource} />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
