import { Action, createActions, handleActions } from 'redux-actions';

export interface CommonCacheState {
  alarm: boolean;
  rpmTheme: 'light' | 'dark';
  showSinglePatient: boolean;
}

export interface CommonCachePayload {
  alarm?: boolean;
  rpmTheme?: 'light' | 'dark';
}

export interface CommonCacheReducer {
  [action: string]: (
    state: CommonCacheState,
    action?: Action<CommonCachePayload>,
  ) => CommonCacheState;
}

export const defaultState: CommonCacheState = {
  alarm: false,
  rpmTheme: 'light',
  showSinglePatient: true,
};

const prefix = {
  prefix: 'COMMON-CACHE', // String used to prefix each type
  namespace: '--', // Separator between prefix and type.  Default: `/`
};

export const { setCommonCache } = createActions<CommonCachePayload>(
  {
    SET_COMMON_CACHE: (commonCache) => {
      return commonCache;
    },
  },
  prefix,
);

const commonCacheReducer = {
  SET_COMMON_CACHE: (
    state: CommonCacheState,
    { payload: { alarm, rpmTheme } }: Action<CommonCachePayload>,
  ): CommonCacheState => {
    return {
      ...state,
      alarm: alarm || false,
      rpmTheme: rpmTheme || 'light',
    };
  },
} as CommonCacheReducer;

export const commonCache = handleActions<CommonCacheState>(
  commonCacheReducer,
  defaultState,
  prefix,
);
