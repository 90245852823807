import { combineReducers } from 'redux';

import { session } from 'reducers/session';
import { loader } from 'reducers/loader';
import { SessionState } from 'reducers/session/session';
import { LoaderState } from 'reducers/loader/loader';
import { timeZone } from 'reducers/timezone';
import { TimeZoneState } from 'reducers/timezone/timeZone';
import { commonCache } from 'reducers/commonCache';
import { CommonCacheState } from 'reducers/commonCache/commonCache';
import {
  SettingInfoState,
  settingInfo,
} from 'reducers/settingInfo/settingInfo';

export interface ApplicationState {
  session: SessionState;
  loader: LoaderState;
  timeZone: TimeZoneState;
  settingInfo: SettingInfoState;
  commonCache: CommonCacheState;
}

export default combineReducers<ApplicationState>({
  session,
  loader,
  timeZone,
  settingInfo,
  commonCache,
});
