import { AxiosRequestConfig } from 'axios';
import { getStoredAccessToken } from 'utils/localStorage';
import { timeZone } from 'utils/util';

const devUrl = process.env.REACT_APP_API_DEV_URL || '';
const prodUrl = process.env.REACT_APP_API_PROD_URL || '/api/backend';

export const apiBaseUrl =
  process.env.NODE_ENV === 'development' ? devUrl : prodUrl;

// eslint-disable-next-line no-shadow
export enum AuthActionsEnum {
  AUTH_ACTION_VERIFY = 'verifySignupSetPasswordLong',
  AUTH_ACTION_SEND_RESTORE = 'sendResetPwd',
  AUTH_ACTION_RESTORE = 'resetPwd',
}

export const LOGOUT_TIMEOUT = 15 * 60 * 1000;

export interface Config {
  paths: {
    baseUrl: string;
    [route: string]: string;
  };
}

const tenantPaths = {
  tenant: '/tenant',
  tenantDevice: '/device',
};

export const config: Config = {
  paths: {
    api: apiBaseUrl,
    baseUrl: '/',
    signIn: '/sign-in',
    download: '/download',
    forbidden: '/403',
    notFound: '/404',
    addPath: '/add',
    editPath: '/edit',
    profile: '/profile',
    pmManagement: '/pm-management',
    adminManagement: '/admin-management',
    techManagement: '/tech-management',
    physicianManagement: '/physician-management',
    researchersManagement: '/researchers-management',
    clinicalTrialCoordinatorManagement: '/ctc-management',
    patientManagement: '/patient-management',
    patients: '/patients',
    devices: '/devices',
    clinics: '/clinics-management',
    changePassword: '/change-password',
    restorePassword: '/restore-password',
    verifyPassword: '/verify-password',
    monitoringProtocols: '/monitoring-protocols',
    dashboard: '/dashboard',
    patientDashboard: '/patient-dashboard',
    disclosureView: '/disclosure-view',
    ecgDataChunks: '/ecg-data-chunks',
    pec: '/pec',
    reports: '/report',
    generateReport: '/generate-report',
    reportsPdf: '/reportsPdf',
    settings: '/settings',
    analysisSetting: '/analysis-setting',
    otherSetting: '/other-setting',
    groupManagement: '/groupmanagement',
    historyView: '/history',
    ecgRecord: '/ecg-record',
    sites: '/sites',
    studies: '/studies',
    studiesManagement: '/studies-management',
    userManagement: '/user-management',
    superAdminManagement: 'super-admin-management',
    st: 'studies-sites',
    templateManagement: '/template-management',
    emailTemplate: '/email',
    smsTemplate: '/sms',
    dataAnalytics: '/dataAnalytics',
    assignedUsers: '/assignedUsers',
    dataAnalyticsDetails: '/details',

    rpm: '/rpm',
    rpmEcg: '/rpm/ecg',
    rpmRT: '/rpm/rt',
    rpmRTList: '/rpm/rt/list',
    addRpmPatient: '/rpm/patient/add',

    orderManagement: '/order',

    ...tenantPaths,
  },
};

export const axiosDefaultConfig = (): AxiosRequestConfig =>
  getStoredAccessToken()
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getStoredAccessToken()}`,
          timeZone: timeZone(),
        },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
          timeZone: timeZone(),
        },
      };

export interface ParamId {
  id: string;
  tenantName: string;
}

interface CustomClinicParam {
  insurance: string[];
  predicate: string[];
}

export const customClinicConfig: CustomClinicParam = {
  predicate: ['Predicate', 'Predicate-Test', 'testUAT', 'Predicate-Reallife'],
  insurance: [
    'Eric4-0',
    'vl123',
    'vl123demo',
    'Vivalink-202301',
    'VL-RPM',
    'ClinicA',
  ],
};
