// eslint-disable-next-line no-use-before-define
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { config } from 'core/config';
import { rules } from 'core/rules';
import { ApplicationState } from 'store/combineReducers';
import { RoleName } from 'core/models/roleModel';
import { quickLoginApi } from 'pages/signIn/signIn.service';
import { statusCodesCheck } from 'utils/api';
import getCurrentUser from 'routes/routes.service';
import { RetryBtn } from '..';

export interface PrivateRouterRouteProps extends RouteProps {
  isAuthenticated: boolean;
  title?: string;
  metaContent?: string;
}

function checkRouteRules(roleName: RoleName, path: string): boolean {
  return rules()?.[roleName]?.includes(path);
}

export function rbacProcess(
  path?: string | string[],
  roleName?: RoleName,
): boolean {
  let isAccess = false;

  if (!roleName) {
    return false;
  }

  if (typeof path === 'string') {
    isAccess = checkRouteRules(roleName, path);
  }
  if (Array.isArray(path)) {
    isAccess = path.map((p) => checkRouteRules(roleName, p)).every((el) => el);
  }
  return isAccess;
}

interface RenderComponent extends PrivateRouterRouteProps {
  roleName?: RoleName;
  title?: string;
  metaContent?: string;
}

const renderComponent = ({
  isAuthenticated,
  path,
  roleName,
  children,
  title,
  metaContent,
}: RenderComponent) => (): ReactElement | ReactNode => {
  if (isAuthenticated) {
    if (!roleName) {
      return <RetryBtn />;
    }
    if (!rbacProcess(path, roleName)) {
      // return <Redirect to={config.paths.forbidden} />;
    }
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaContent} />
        </Helmet>
        {children}
      </>
    );
  }

  return (
    <Redirect
      to={{
        pathname: config.paths.signIn,
      }}
    />
  );
};

export const PrivateRoute = ({
  isAuthenticated,
  children,
  title,
  metaContent,
  ...rest
}: PrivateRouterRouteProps): ReactElement => {
  const { location, path, exact, component, sensitive, strict } = rest;
  const { user } = useSelector((state: ApplicationState) => state.session);

  const [isOver, setIsOver] = useState(false);

  const quickLogin = useCallback(async (search?: string) => {
    const searchParams = new URLSearchParams(search);
    // http://localhost:3000?vcloudToken=xxx
    const vcloudToken = searchParams.get('vcloudToken');
    if (vcloudToken != null) {
      const response = await quickLoginApi(vcloudToken);
      if (response && statusCodesCheck(response)) {
        await getCurrentUser();
      }
    }
    setIsOver(true);
  }, []);

  useEffect(() => {
    quickLogin(location?.search);
  }, [location]);

  if (!isOver) {
    return <></>;
  }

  return (
    <Route
      location={location}
      path={path}
      exact={exact}
      component={component}
      sensitive={sensitive}
      strict={strict}
      render={renderComponent({
        isAuthenticated,
        children,
        path,
        roleName: user?.roles[0].name,
        title,
        metaContent,
      })}
    />
  );
};
