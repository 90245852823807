import { RawUser } from 'core/models/userModel';
import { ReimbursementCode } from 'core/models/reimbursementCodeModel';
import dayjs, { Dayjs } from 'dayjs';
import uniqid from 'uniqid';
import utc from 'dayjs/plugin/utc';

import { Device, RawDevice } from 'core/models/deviceModel';
import {
  MonitoringProtocol,
  MonitoringProtocolModel,
  RawMonitoringProtocol,
} from 'core/models/monitoringProtocol/monitoringProtocol';
import { PatientBase, PatientModelBase } from 'core/models/patientModelBase';
import { UserBase } from 'core/models/userModelBase';
import { DeviceModel, ReimbursementCodeModel, UserModelBase } from '.';

dayjs.extend(utc);

export interface PatientRouteParams {
  id: string;
  studyId: string;
  eventId: string;
}

export interface ThirdPartyNotification {
  email?: string;
  phone?: string;
  note?: string;
}

interface Studies {
  id: string;
  name: string;
}

export interface Patient extends PatientBase {
  birthDate: string | Dayjs;
  physician: any;
  isOpenPrescribingPhysician: boolean;
  prescribingPhysicianName: string;
  monitoringProtocol: any;
  devices: Device[];
  codes?: any;
  internalId?: string;
  subjectId?: string;
  siteName?: string;
  site?: string;
  studiesList?: Studies[];
  selfDefinedProtocol?: boolean;
  monitoringProtocolCustomStatus?: boolean;
  nowMonitoringProtocolId?: string;
  clinicCustomStatus: boolean;
  deviceMismatch: boolean;
  thirdPartyNotification?: ThirdPartyNotification[];
  timeZoneOffset: number;
  timeZoneName: string;
  isRpm: boolean;
  rpmGroupIds?: string[];
  deviceTypeCodes?: number[];
}

export interface RawPatient {
  internalId: string | undefined;
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  physician: any | string;
  isOpenPrescribingPhysician: boolean;
  prescribingPhysicianName: string;
  monitoringProtocol: any | string;
  devices: RawDevice[];
  codes?: any;
  clinic?: string;
  subjectId: string;
  siteName?: string;
  studiesList?: Studies[];
  selfDefinedProtocol?: boolean;
  monitoringProtocolCustomStatus?: boolean;
  nowMonitoringProtocolId: string;
  clinicCustomStatus: boolean;
  deviceMismatch: boolean;
  thirdPartyNotificationEmails?: any;
  timeZoneOffset: number;
  timeZoneName: string;
  isRpm: boolean;
  rpmGroupIds?: string[];
  name?: string;
  deviceTypeCodes?: number[];
}

export const PATIENT_TITLE = 'Patient';

export class PatientModel extends PatientModelBase implements Patient {
  birthDate: string | Dayjs;

  internalId?: string | '';

  physician: any;

  isOpenPrescribingPhysician: boolean;

  prescribingPhysicianName: string;

  monitoringProtocol: any;

  devices: Device[];

  codes?: any;

  clinic?: string;

  subjectId?: string;

  siteName?: string;

  studiesList?: Studies[];

  selfDefinedProtocol?: boolean;

  monitoringProtocolCustomStatus?: boolean;

  nowMonitoringProtocolId?: string;

  clinicCustomStatus: boolean;

  deviceMismatch: boolean;

  thirdPartyNotificationEmails?: any;

  timeZoneOffset: number;

  timeZoneName: string;

  isRpm: boolean;

  rpmGroupIds?: string[];

  deviceTypeCodes?: number[];

  constructor(data: RawPatient) {
    super(data);
    this.clinic = data.clinic;
    this.birthDate =
      data.birthDate == null || data.birthDate === ''
        ? ''
        : dayjs(data.birthDate).utc();
    this.internalId = data.internalId;
    this.subjectId = data.subjectId;
    if (typeof data.physician === 'string') {
      throw Error('RawPatient physician not populated');
    } else {
      this.physician = data.physician && new UserModelBase(data.physician);
    }
    this.isOpenPrescribingPhysician = data.isOpenPrescribingPhysician;
    this.prescribingPhysicianName = data.prescribingPhysicianName;
    if (typeof data.monitoringProtocol === 'string') {
      throw Error('RawPatient monitoringProtocol not populated');
    } else {
      this.monitoringProtocol =
        data.monitoringProtocol &&
        new MonitoringProtocolModel(data.monitoringProtocol);
    }

    if (data.devices != null) {
      this.devices = data.devices.map(
        (device: RawDevice) => new DeviceModel(device),
      );
    } else {
      this.devices = [];
    }

    this.codes = data.codes;

    this.siteName = data.siteName;

    this.studiesList = data.studiesList;

    this.selfDefinedProtocol = data.selfDefinedProtocol;

    this.monitoringProtocolCustomStatus = data.monitoringProtocolCustomStatus;

    this.nowMonitoringProtocolId = data.nowMonitoringProtocolId;

    this.clinicCustomStatus = data.clinicCustomStatus;

    this.deviceMismatch = data.deviceMismatch;

    this.thirdPartyNotificationEmails = data.thirdPartyNotificationEmails;

    this.timeZoneOffset = data.timeZoneOffset;

    this.timeZoneName = data.timeZoneName;

    this.isRpm = data.isRpm;

    this.rpmGroupIds = data.rpmGroupIds;
    this.deviceTypeCodes = data.deviceTypeCodes;
  }
}
