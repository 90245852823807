// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useCallback } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';

interface CancelBtProps {
  path?: string;
  title?: string;
}

export default function CancelBtn({
  path,
  title,
}: CancelBtProps): ReactElement {
  const history = useHistory();

  const handleBack = useCallback(() => {
    if (path) {
      return history.push(path);
    }
    return history.go(-1);
  }, [history, path]);

  return (
    <Button type="default" onClick={handleBack}>
      {title === 'Cancel' ? t('cancel') : title}
    </Button>
  );
}

CancelBtn.defaultProps = {
  path: '',
  title: 'Cancel',
};
