/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line no-use-before-define
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Grid,
  Input,
  Layout as LayoutAnt,
  Modal,
  Row,
  Select,
  Space,
} from 'antd';

import { config } from 'core/config';
import 'layout/styles.less';
import { ApplicationState } from 'store/combineReducers';
import { Logo } from 'shared/components/logo';
import { SearchOutlined } from '@ant-design/icons';
import { RolesEnum } from 'core/models';
import LogoNew from 'shared/components/logo/LogoNew';
import { useTranslation } from 'react-i18next';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import { getStoredLanguage, getStoredUserLanguage } from 'utils/localStorage';
import { SideBar } from './components/sideBar';
import { AvatarDropdown } from './components/avatarDropdown';

const { useBreakpoint } = Grid;
interface LayoutProps {
  children: ReactNode[] | ReactNode;
}

const { Header, Content } = LayoutAnt;

export default function Layout({ children }: LayoutProps): ReactElement {
  const { t } = useTranslation();
  const history: History = useHistory();
  const { lg, xl } = useBreakpoint();
  const { isAuth } = useSelector((state: ApplicationState) => state.session);
  const [form] = Form.useForm();
  const { user } = useSelector((state: ApplicationState) => state.session);

  useEffect(() => {
    const unListen = history.listen(() => {
      Modal.destroyAll();
    });
    return (): void => {
      unListen();
    };
  }, [history]);

  const language = useMemo(() => {
    const vivalink_language = getStoredUserLanguage() ?? 'en-US';
    if (vivalink_language === 'zh-CN') {
      return zhCN;
    }
    return enUS;
  }, [getStoredUserLanguage()]);

  const isSiderLayout = useCallback((pathname: string) => {
    return (
      pathname === config.paths.termsOfUse ||
      pathname === config.paths.privacyPolicy ||
      pathname.includes(config.paths.signUp)
    );
  }, []);

  if (history.location.pathname.startsWith(config.paths.download)) {
    if (!isAuth) {
      history.push(`${config.paths.signIn}`, {
        sourcePath: history.location.pathname + history.location.search,
      });
    }
    return (
      <ConfigProvider locale={language}>
        <LayoutAnt className="full-height-page main-wrapper">
          <Content className="content">{children}</Content>
        </LayoutAnt>
      </ConfigProvider>
    );
  }

  if (!isAuth || isSiderLayout(history.location.pathname)) {
    return (
      <ConfigProvider locale={language}>
        <LayoutAnt className="full-height-page main-wrapper">
          <Header className="yahuikeji-layout-header">
            <Row style={{ height: '60px' }} justify="start" align="middle">
              <Logo dark />
            </Row>
          </Header>
          <Content className="content">{children}</Content>
        </LayoutAnt>
      </ConfigProvider>
    );
  }
  const onFinish = (values: any) => {
    const _v = values;
    if (values.keyword === '') {
      _v.keyword = undefined;
    }
    history.push(`/${values.type}`, values);
  };
  return (
    <ConfigProvider locale={language}>
      <LayoutAnt className="main-wrapper yahuikeji-layout">
        {isAuth && <SideBar />}
        <LayoutAnt>
          <div className="yahuikeji-main">
            <Header className="yahuikeji-layout-header">
              <Row
                justify="space-between"
                align="middle"
                style={{ height: '60px' }}
              >
                <Col className="yahuikeji-header-title">
                  <Row align="middle">
                    <Col>
                      {user?.clinic?.title}{' '}
                      {user?.siteName != null ? ` / ${user?.siteName}` : ''}
                    </Col>
                    <Col push={1}>
                      {user?.roles?.map(
                        (item: any) =>
                          item.name === RolesEnum.Physician && (
                            <Form
                              form={form}
                              key={item._id}
                              layout="inline"
                              onFinish={onFinish}
                              className="topSearchForm"
                            >
                              <Form.Item name="keyword">
                                <Input
                                  placeholder={t(
                                    'layoutHeader.form.keyword.placeholder',
                                  )}
                                  allowClear
                                />
                              </Form.Item>
                              <Form.Item
                                name="type"
                                rules={[{ required: true }]}
                              >
                                <Select
                                  style={{ width: '120px' }}
                                  placeholder={t(
                                    'layoutHeader.form.type.placeholder',
                                  )}
                                  allowClear
                                >
                                  <Select.Option value="dashboard">
                                    {t(
                                      'layoutHeader.form.type.options.dashboard',
                                    )}
                                  </Select.Option>
                                  <Select.Option value="patients">
                                    {t(
                                      'layoutHeader.form.type.options.patients',
                                    )}
                                  </Select.Option>
                                  <Select.Option value="report">
                                    {t(
                                      'layoutHeader.form.type.options.Reports',
                                    )}
                                  </Select.Option>
                                  <Select.Option value="patients/ecg-record">
                                    {t(
                                      'layoutHeader.form.type.options.ecgRecord',
                                    )}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  icon={<SearchOutlined />}
                                  style={{ minWidth: '50px', padding: '0' }}
                                />
                              </Form.Item>
                            </Form>
                          ),
                      )}
                    </Col>
                  </Row>
                </Col>
                <div />
                <Col span={10}>
                  <Row justify="end" align="middle">
                    <Col>
                      <AvatarDropdown />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Header>
            <div className="yahuikeji-layout-box">
              <Content className="content">{children}</Content>
            </div>
          </div>
        </LayoutAnt>
      </LayoutAnt>
    </ConfigProvider>
  );
}
