/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useCallback } from 'react';
import { Dropdown, MenuProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { SvgComponent } from 'shared/components';

import './style.less';
import { setStoreLanguage } from 'utils/localStorage';
import { changeLanguageTmp } from 'i18n';
import { DownOutlined } from '@ant-design/icons';

export default function LocalesDropdown(): ReactElement {
  const { t } = useTranslation();

  const hangeLanguage = useCallback((e): void => {
    setStoreLanguage(e.key);
    changeLanguageTmp();
    window.location.reload();
  }, []);

  const items: MenuProps['items'] = [
    {
      key: 'en-US',
      onClick: hangeLanguage,
      label: <div>English</div>,
    },
    {
      key: 'zh-CN',
      onClick: hangeLanguage,
      label: <div>简体中文</div>,
    },
  ];

  return (
    <div className="yahuikeji-locales">
      <Dropdown menu={{ items }} placement="bottom" arrow>
        <div className="yahuikeji-locales-dropdown">
          <SvgComponent
            iconName="en-cn"
            width={12}
            height={12}
            className="anticon"
          />
          <div>{t('globalizationKey')}</div>
          <SvgComponent
            iconName="triangle"
            width={12}
            height={12}
            className="anticon"
          />
        </div>
      </Dropdown>
    </div>
  );
}
