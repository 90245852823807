import { Action, createActions, handleActions } from 'redux-actions';

import { TimeZone } from 'core/models/timeZoneModel';

export interface TimeZoneState {
  timeZone: TimeZone[];
}

export interface TimeZonePayload {
  timeZone?: TimeZone[];
}

export interface TimeZoneReducer {
  [action: string]: (
    state: TimeZoneState,
    action?: Action<TimeZonePayload>,
  ) => TimeZoneState;
}

export const defaultState: TimeZoneState = {
  timeZone: [],
};

const prefix = {
  prefix: 'TIMEZONE', // String used to prefix each type
  namespace: '--', // Separator between prefix and type.  Default: `/`
};

export const { setTimeZone } = createActions<TimeZonePayload>(
  {
    SET_TIME_ZONE: (timeZone) => {
      return timeZone;
    },
  },
  prefix,
);

const timeZoneReducer = {
  SET_TIME_ZONE: (
    state: TimeZoneState,
    { payload: { timeZone } }: Action<TimeZonePayload>,
  ): TimeZoneState => {
    return {
      ...state,
      timeZone: timeZone || [],
    };
  },
} as TimeZoneReducer;

export const timeZone = handleActions<TimeZoneState>(
  timeZoneReducer,
  defaultState,
  prefix,
);
