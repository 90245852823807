// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Button, Row, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

export default function RetryBtn(): ReactElement {
  function handleClick(): void {
    window.location.reload();
  }

  return (
    <Row justify="center" align="middle">
      <Tooltip title="retry">
        <Button
          onClick={handleClick}
          type="primary"
          shape="circle"
          icon={<ReloadOutlined />}
        />
      </Tooltip>
    </Row>
  );
}
