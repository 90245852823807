// eslint-disable-next-line no-shadow
export enum ClinicStatusEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface ReportTemplateSettingPatientInfo {
  patientName: boolean;
  subjectId: boolean;
  dateOfBirth: boolean;
  timezone: boolean;
  monitoringProtocol: boolean;
  physician: boolean;
  prescribingPhysician: boolean;
  gender: boolean;
  heightOrWeight: boolean;
  occupation: boolean;
}

export interface ReportTemplateSettingEnrollmentPeriod {
  duration: boolean;
  startOrEnd: boolean;
}

export interface ReportTemplateSettingMonitoringSummary {
  monitoringPeriod: boolean;
  monitoringTime: boolean;
  analysisTime: boolean;
  noise: boolean;
}

export interface ReportTemplateSettingPreliminaryFindings {
  automatedSummary: boolean;
  signature: boolean;
}

export interface ReportTemplateSettingHeader {
  deviceInfo: boolean;
  patientInfo: boolean;
}

export interface ReportTemplateSettingEcg {
  title?: string;
  header: ReportTemplateSettingHeader;
  patientInfo: ReportTemplateSettingPatientInfo;
  enrollmentPeriod: ReportTemplateSettingEnrollmentPeriod;
  monitoringSummary: ReportTemplateSettingMonitoringSummary;
  preliminaryFindings: ReportTemplateSettingPreliminaryFindings;
  heartRateTrend: boolean;
  pacAndPvc: boolean;
  stripIndex: boolean;
}

export interface ReportTemplateSetting {
  ecg: ReportTemplateSettingEcg;
}

export interface ReportTemplateSettingCSVTreeNode {
  key: string;
  title: string;
  category?: string;
  children?: ReportTemplateSettingCSVTreeNode[];
}

export interface ReportTemplateSettingCSV {
  dataSource?: ReportTemplateSettingCSVTreeNode[];
  targetSource?: ReportTemplateSettingCSVTreeNode[];
  defaultSource?: ReportTemplateSettingCSVTreeNode[];
}

export interface DataAnalytics {
  programManagerStatus: boolean;
  adminStatus: boolean;
  physicianStatus: boolean;
  technicianStatus: boolean;
  researcherStatus: boolean;
}

export interface Clinic {
  id: string;
  title: string;
  status: ClinicStatusEnum;
  phone: string;
  tenantId?: string;
  tenantKey?: string;
  sitesStatus: boolean;
  studiesStatus: boolean;
  researcherRoleStatus: boolean;
  clinicalTrialCoordinatorStatus: boolean;
  subjectIdStatus: boolean;
  kitIdStatus: boolean;
  demoStatus: boolean;
  temperatureUnit?: string;
  isDct?: boolean;
  countryCode?: string;
  address?: string;
  customStatus?: boolean;
  cgmStatus: boolean;
  rpmStatus: boolean;
  pageViewStatus: boolean;
  prosStatus: boolean;
  dataAnalytics: DataAnalytics;
  vcloudDomain?: string;
  productOrService?: string;
  loginTimeout?: number;
  loginTimeoutType?: string;
}

export interface RawClinic {
  _id: string;
  title: string;
  status: ClinicStatusEnum;
  phone: string;
  tenantId?: string;
  tenantKey?: string;
  sitesStatus: boolean;
  studiesStatus: boolean;
  researcherRoleStatus: boolean;
  clinicalTrialCoordinatorStatus: boolean;
  subjectIdStatus: boolean;
  kitIdStatus: boolean;
  demoStatus: boolean;
  temperatureUnit?: string;
  countryCode?: string;
  address?: string;
  customStatus?: boolean;
  cgmStatus: boolean;
  rpmStatus: boolean;
  pageViewStatus: boolean;
  prosStatus: boolean;
  dataAnalytics: DataAnalytics;
  vcloudDomain?: string;
  productOrService?: string;
  loginTimeout?: number;
  loginTimeoutType?: string;
}

export const CLINIC_TITLE = 'Clinic';

function isDct(clinic: RawClinic): boolean {
  try {
    if (
      clinic.studiesStatus === true ||
      clinic.researcherRoleStatus === true ||
      clinic.subjectIdStatus === true
    ) {
      return true;
    }
  } catch (error) {
    //
  }
  return false;
}
export class ClinicModel implements Clinic {
  id: string;

  title: string;

  status: ClinicStatusEnum;

  phone: string;

  tenantId?: string;

  tenantKey?: string;

  sitesStatus: boolean;

  studiesStatus: boolean;

  researcherRoleStatus: boolean;

  clinicalTrialCoordinatorStatus: boolean;

  subjectIdStatus: boolean;

  kitIdStatus: boolean;

  demoStatus: boolean;

  temperatureUnit?: string;

  countryCode?: string;

  address?: string;

  isDct?: boolean;

  customStatus?: boolean;

  cgmStatus: boolean;

  rpmStatus: boolean;

  pageViewStatus: boolean;

  prosStatus: boolean;

  dataAnalytics: DataAnalytics;

  vcloudDomain?: string;

  productOrService?: string;

  loginTimeout?: number;

  loginTimeoutType?: string;

  constructor(data: RawClinic) {
    this.id = data._id;
    this.title = data.title;
    this.status = data.status || ClinicStatusEnum.disabled;
    this.phone = data.phone;
    this.tenantId = data.tenantId;
    this.tenantKey = data.tenantKey;
    this.sitesStatus = data.sitesStatus || false;
    this.studiesStatus = data.studiesStatus || false;
    this.researcherRoleStatus = data.researcherRoleStatus || false;
    this.clinicalTrialCoordinatorStatus =
      data.clinicalTrialCoordinatorStatus || false;
    this.subjectIdStatus = data.subjectIdStatus || false;
    this.kitIdStatus = data.kitIdStatus || false;
    this.demoStatus = data.demoStatus || false;
    this.temperatureUnit = data.temperatureUnit;
    this.isDct = isDct(data);
    this.countryCode = data.countryCode;
    this.address = data.address;
    this.customStatus = data.customStatus;
    this.cgmStatus = data.cgmStatus;
    this.rpmStatus = data.rpmStatus;
    this.pageViewStatus = data.pageViewStatus;
    this.prosStatus = data.prosStatus;
    this.dataAnalytics = data.dataAnalytics;
    this.vcloudDomain = data.vcloudDomain;
    this.productOrService = data.productOrService;
    this.loginTimeout = data.loginTimeout;
    this.loginTimeoutType = data.loginTimeoutType;
  }
}
